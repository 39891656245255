import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiEndpoint} from '../../environments/api-endpoint';
import {finalize} from 'rxjs/operators';
import {ProgressService} from './progress.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CalculatePriceResponse, CheckoutRequest, CheckoutResponse, ConfigureData, OrderDetails} from '../data/order';
import {Book} from '../data/book';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
  })
export class OrderService {

  constructor(private progressService: ProgressService, private httpClient: HttpClient) {
  }

  getConfigureData(): Observable<ConfigureData> {
    const task = this.progressService.addTask('getBook');
    return this.httpClient.get<ConfigureData>(ApiEndpoint.getPricesEndpoint).pipe(
      finalize(() => this.progressService.removeTask(task.id))
    );
  }

  calculatePrice(book: Book): Observable<CalculatePriceResponse> {
    const task = this.progressService.addTask('calculatePrice');
    return this.httpClient.post<CalculatePriceResponse>(ApiEndpoint.calculatePriceEndpoint, book).pipe(
      finalize(() => this.progressService.removeTask(task.id))
    );
  }

  getStripeToken(cardData: any): Observable<any> {
    const formBody = new FormData();
    formBody.append('card[number]', cardData.cardNumber);
    formBody.append('card[exp_month]', cardData.expMonth);
    formBody.append('card[exp_year]', cardData.expYear);
    formBody.append('card[cvc]', cardData.Cvc);

    return this.httpClient.post('https://api.stripe.com/v2/tokens',
      formBody,
      {
        headers: new HttpHeaders()
          .append('Authorization', `Bearer ${environment.stripeKey}`)
          .append('Content-Type', 'application/x-www-form-urlencoded')
      });
  }

  checkout(request: CheckoutRequest): Observable<CheckoutResponse> {
    const task = this.progressService.addTask('checkout');
    return this.httpClient.post<CheckoutResponse>(ApiEndpoint.checkoutEndpoint, request).pipe(
      finalize(() => this.progressService.removeTask(task.id))
    );
  }


  getOrderDetails(orderUuid: string): Observable<OrderDetails> {
    const task = this.progressService.addTask('getOrderDetails');
    return this.httpClient.get<OrderDetails>(`${ApiEndpoint.getOrderDetailsEndpoint}?uuid=${orderUuid}`).pipe(
      finalize(() => this.progressService.removeTask(task.id))
    );
  }

  generatePdf(book: Book): Observable<any> {
    return this.httpClient.post(ApiEndpoint.generatePdfEndpoint, book);
  }

  shareDiscount(emailList: string[]): Observable<any> {
    const task = this.progressService.addTask('shareDiscount');
    return this.httpClient.post(ApiEndpoint.shareDiscountEndpoint, { emailList }).pipe(
      finalize(() => this.progressService.removeTask(task.id))
    );
  }
}

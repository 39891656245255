<header class="bg-white shadow-sm">
  <nav class="navbar navbar-expand">
    <div class="container">
      <a href="" [routerLink]="['/profile']" class="back back-button">
        <fa-icon [icon]="faAngleLeft"></fa-icon>
      </a>
      <div class="app-logo" [style.backgroundImage]="'url(\'/assets/img/pic_roc_logo.svg\')'"></div>
      <p class="title">
        {{book?.title}}
        <a href="" (click)="editPhotobookName($event)" class="edit">
          <fa-icon [icon]="faPencilAlt"></fa-icon>
        </a>
      </p>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <div class="profile-picture" [style.backgroundImage]="CredentialsService.profilePictureUrl"></div>
        <ul class="navbar-nav">
          <li class="nav-item dropdown" dropdown>
            <a id="navbarDropdown" class="nav-link dropdown-toggle" dropdownToggle href="" role="button"
               (click)="$event.preventDefault()"
               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <fa-icon [icon]="faChevronDown"></fa-icon>
              <span class="caret"></span>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown" *dropdownMenu>
              <a class="dropdown-item" href="" [routerLink]="'/profile'">Home</a>
              <a class="dropdown-item" href="">Orders</a>
              <a class="dropdown-item" href=""
                 (click)="$event.preventDefault(); credentialsService.signOut().subscribe()">Logout</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div class="container">
    <div class="row" [style.justifyContent]="'center'">
      <ul class="tabs">
        <li [ngClass]="{'active' : activeTab == 'book' }">
          <a [routerLink]="['/book/' + book?.id + '/book']">Book view</a>
        </li>
        <li [ngClass]="{'active' : activeTab == 'page' }">
          <a [routerLink]="['/book/' + book?.id + '/page']">Page view</a>
        </li>
      </ul>
    </div>
  </div>
</header>
<main>
  <div class="container top" [style.overflowX]="'hidden'">
    <ng-container *ngIf="activeTab == 'book'">
      <!--<div class="row"> TODO: how to track if user push this button? what happens after all?
        <div class="col-md">
          <div class="jumbotron">
            You've got some amazing photos! <br/>
            Add 10 extra pages for only $9.
            <button type="button" class="btn btn&#45;&#45;white">Add now</button>
          </div>
        </div>
      </div>-->
      <div class="row" [style.paddingBottom]="'100px'">
        <div class="col-md">
          <div class="book" [ngClass]="[book?.orientation ? book?.orientation : '', editing ? 'editing' : '']">
            <div class="pages">
              <ng-container
                *ngTemplateOutlet="layoutTemplate; context: {item: this.book?.cover, index: -1}"></ng-container>
            </div>
            <div class="pages" cdkDropList (cdkDropListDropped)="listReordered($event)">
              <app-reorderable-list [items]="book?.pages" (orderChanged)="listReordered($event)"
                                    [editTemplate]="editTemplate"
                                    [itemTemplate]="itemTemplate" [disabled]="!editing">
                <ng-template #itemTemplate let-item="item" let-index="index">
                  <ng-container *ngTemplateOutlet="layoutTemplate; context: {item: item, index: index}"></ng-container>
                </ng-template>
                <ng-template #editTemplate let-item="item" let-index="index">
                  <ng-container
                    *ngTemplateOutlet="pageControlTemplate; context: {item: item, index: index}"></ng-container>
                </ng-template>
              </app-reorderable-list>
            </div>
            <div class="pages">
              <ng-container
                *ngTemplateOutlet="layoutTemplate; context: {item: this.book?.backcover, index: -2}"></ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="activeTab == 'page'">
      <div class="row">
        <div class="col-md book-container" [ngClass]="[activePageIndex == -2 || activePageIndex == -1 ? '' : (activePageIndex % 2 === 0 ? 'left-image' : 'right-image')]">
          <div class="book page-view"
               [ngClass]="[book?.orientation ? book?.orientation : '', editing ? 'editing' : '']">
            <div class="pages">
              <ng-container *ngTemplateOutlet="layoutTemplate; context: {item: activePage}"></ng-container>
            </div>
          </div>
          <div class="book page-view"
               *ngIf="activePageIndex != -1 && activePageIndex != -2"
               [ngClass]="[book?.orientation ? book?.orientation : '', activePageIndex % 2 === 0 ? 'right-side' : 'left-side']">
            <div class="pages">
              <ng-container
                *ngTemplateOutlet="layoutTemplate; context: {item: getImageForPageViewSide()}"></ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="row" [ngClass]="[book?.orientation ? book?.orientation : '']">
        <div class="col-md">
          <div class="pagination">
            <a href="" class="prev" [ngClass]="activePageIndex < 0 && activePageIndex != -2 ? 'disabled' : ''" (click)="changePage($event, false)">
              <fa-icon [icon]="faLongArrowAltLeft"></fa-icon>
            </a>
            <div class="number">{{activePageIndex == -1 ? 'Cover' : (activePageIndex == -2 ? 'Backcover' : ('Page ' + (activePageIndex + 1) + '/' + book?.pageCount))}}</div>
            <a href="" class="next" [ngClass]="activePageIndex == -2 ? 'disabled' : ''"
               (click)="changePage($event, true)">
              <fa-icon [icon]="faLongArrowAltRight"></fa-icon>
            </a>
          </div>
        </div>
      </div>
      <div class="row" [style.justifyContent]="'center'" [style.marginTop]="'1.5em'"
           [ngClass]="[book?.orientation ? book?.orientation : '']">
        <a *ngIf="editing && activePageIndex != -1 && activePageIndex != -2"
           href="" (click)="$event.preventDefault(); showChangeLayoutDialog = true" class="text-center">
          Change page layout
        </a>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="showInputDialog">
    <app-input-dialog
      [maxLength]="50"
      [multilineText]="false"
      positiveButtonText="Change name"
      negativeButtonText="Cancel"
      [title]="'Give your photobook a new title!'"
      placeholder="My photobook"
      (closeDialog)="postNewName($event)"></app-input-dialog>
  </ng-container>
  <ng-container *ngIf="editingLayoutElementData">
    <app-image-editor [data]="editingLayoutElementData"
                      [book]="book"
                      [pageIndex]="activePageIndex"
                      (save)="onSaveLayoutElement($event.photoData, $event.cover, $event.backcover)"
                      (photoChange)="photoChanged($event.pageIndex, $event.imageIndex, $event.photo, $event.cover, $event.backcover)"
                      (deletePhoto)="deletePhoto($event)"
                      (useAsCover)="setCover($event)"
                      (useAsBackCover)="setBackCover($event);"
                      (closeDialog)="editingLayoutElementData = undefined"></app-image-editor>
  </ng-container>
  <ng-container *ngIf="instagramErrorDialog">
    <app-ok-dialog
      title="Error in instagram login"
      positiveButtonText="Close"
      (closeDialog)="instagramErrorDialog = false;">
    </app-ok-dialog>
  </ng-container>
  <ng-container *ngIf="showChangeLayoutDialog">
    <app-layout-change (closeDialog)="showChangeLayoutDialog = false" (layoutChanged)="layoutChange($event)"
                       [layoutFormat]="book?.orientation"></app-layout-change>
  </ng-container>
  <ng-container *ngIf="showEmptyPageWarningDialog">
    <app-ok-dialog
      title="Warning"
      body="Hey! You have at least one page layout where you haven't place an image. There will be a white space instead, are you sure you want to proceed?"
      positiveButtonText="Yes, I'm sure, create my book"
      negativeButtonText="No, I'll fix this"
      (closeDialog)="$event ? goToConfigure(false) : showEmptyPageWarningDialog = false">
    </app-ok-dialog>
  </ng-container>
  <ng-container *ngIf="showOrderBookDialog">
    <app-configure-book-dialog
      [book]="book"
      [numberOfPages]="book?.pageCount"
      [printHTML]="getPrintViewHtml(printView)"
      (closeDialog)="showOrderBookDialog = false;">
    </app-configure-book-dialog>
  </ng-container>
</main>
<footer class="footer bg-white">
  <div class="container text-center">
    <div class="row align-items-center justify-content-center">
      <ng-container *ngIf="activeTab == 'book'">
        <a *ngIf="editing" href="" (click)="$event.preventDefault(); editing = false;" class="text-uppercase"
           style="width: 100%; height: 100%; display: inline-block;">Back</a>
        <button *ngIf="!editing" (click)="$event.preventDefault(); editing = true;" type="button"
                class="btn btn--outline btn--full-width">
          Edit book
        </button>
        <button *ngIf="activeTab == 'book'" type="button" class="btn btn--gradient btn--full-width"
                (click)="goToConfigure()">
          Next
        </button>
      </ng-container>
      <ng-container *ngIf="activeTab == 'page'">
        <button *ngIf="!editing" (click)="$event.preventDefault(); editing = true;" type="button"
                class="btn btn--outline btn--full-width">
          Edit page
        </button>
        <ng-container *ngIf="editing">
          <a *ngIf="editing" href="#" (click)="$event.preventDefault(); pageEditHistory.length > 0 ? undo() : undefined"
             class="text-uppercase" [ngClass]="{'disabled': pageEditHistory.length == 0}"
             style="width: 100%; height: 100%; display: inline-block;">Undo</a>
        </ng-container>
        <ng-container *ngIf="!editing">
        </ng-container>
        <button *ngIf="editing == false" type="button" class="btn btn--gradient btn--full-width"
                (click)="goToConfigure()">
          Next
        </button>
        <button *ngIf="editing == true" (click)="pageEditHistory = []; editing = false;"
                type="button" class="btn btn--gradient btn--full-width">
          Done
        </button>
      </ng-container>
    </div>
  </div>
</footer>

<ng-template let-image="image" let-index="index" let-onlyImage="onlyImage" #imageTemplate>
  <div class="layout-element" #imageHolder [ngClass]="!onlyImage && editing && (!image.url || image.url.trim().length == 0) ? 'empty' : ''"
       (click)="$event.preventDefault(); !onlyImage && editing && (!image.url || image.url.trim().length == 0) ? this.activePageIndex = index : undefined; !onlyImage && editing && (!image.url || image.url.trim().length == 0) ? openLayoutEditor(imageHolder, image) : undefined">
    <div [id]="'image_template_' + index" [style.display]="'none'" (click)="this.activePageIndex = index; openLayoutEditor(imageHolder, image)"></div>
    <img src="{{image.url}}"
         (dragstart)="$event.preventDefault()"
         [style.width]="getImageProperty('size', image) + '%'"
         [style.transform]="getImageProperty('rotate', image, imageHolder) + ' ' +
       getImageProperty('backgroundPositionX', image, imageHolder) + ' ' +
       getImageProperty('backgroundPositionY', image, imageHolder)"/>
    <span class="text" *ngIf="getImageProperty('fontText', image, imageHolder)?.length > 0"
          [style.color]="getImageProperty('fontColor', image, imageHolder)"
          [style.left]="getImageProperty('fontPositionX', image, imageHolder)"
          [style.top]="getImageProperty('fontPositionY', image, imageHolder)"
          [style.lineHeight]="getImageProperty('fontSize', image, imageHolder)"
          [style.fontSize]="getImageProperty('fontSize', image, imageHolder)"
          [innerHTML]="getImageProperty('fontText', image, imageHolder)"></span>
    <div
      class="controls"
      *ngIf="index == undefined"
      [style.visibility]="image.url?.trim().length > 0 ? 'visible' : 'hidden'"
    >
      <a href=""
         [class]="'image_edit_button_' + (index ? index : 'active_page')"
         (click)="$event.preventDefault(); openLayoutEditor(imageHolder, image)"
         class="rename">
        <fa-icon [icon]="faPencilAlt"></fa-icon>
      </a>
    </div>
  </div>
</ng-template>

<ng-template let-index="index" let-onlyImage="onlyImage" #imageControl>
  <div class="controls">
    <a href="" (click)="$event.preventDefault(); moveToPage(index)">
      <fa-icon [icon]="faPencilAlt"></fa-icon>
    </a>
    <a *ngIf="index != -2 && index != -1" href="" (click)="$event.preventDefault()" cdkDragHandle>
      <fa-icon [icon]="faArrowsAlt"></fa-icon>
    </a>
    <a *ngIf="index != -2 && index != -1 && onlyImage" href="" (click)="$event.preventDefault(); document.getElementById('image_template_' + index).click()">
      <fa-icon [icon]="faPlus"></fa-icon>
    </a>
  </div>
</ng-template>

<ng-template let-index="index" let-item="item" #layoutTemplate>
  <div class="page">
    <ng-container [ngSwitch]="item?.layout">
      <div *ngSwitchCase="'1_1'" class="layout-holder">
        <div class="preview">
          <div class="layout-item" [style.width]="'100%'" [style.height]="'100%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[0], index: index, onlyImage: true}"></ng-container>
          </div>
          <ng-container *ngIf="index != undefined">
            <ng-container *ngTemplateOutlet="imageControl; context: {index: index, onlyImage: !item.photoList[0].url}"></ng-container>
          </ng-container>
        </div>
      </div>
      <div *ngSwitchCase="'2_1'" class="layout-holder">
        <div class="preview">
          <div class="layout-item" [style.width]="'50%'" [style.height]="'100%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[0], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.width]="'50%'" [style.height]="'100%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[1], index: index, onlyImage: false}"></ng-container>
          </div>
          <ng-container *ngIf="index != undefined">
            <ng-container *ngTemplateOutlet="imageControl; context: {index: index}"></ng-container>
          </ng-container>
        </div>
      </div>
      <div *ngSwitchCase="'2_2'" class="layout-holder">
        <div class="preview">
          <div class="layout-item" [style.height]="'50%'" [style.width]="'100%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[0], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'50%'" [style.width]="'100%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[1], index: index, onlyImage: false}"></ng-container>
          </div>
          <ng-container *ngIf="index != undefined">
            <ng-container *ngTemplateOutlet="imageControl; context: {index: index}"></ng-container>
          </ng-container>
        </div>
      </div>
      <div *ngSwitchCase="'3_1'" class="layout-holder">
        <div class="preview">
          <div class="layout-item" [style.height]="'50%'" [style.width]="'50%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[0], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'50%'" [style.width]="'50%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[1], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'50%'" [style.width]="'100%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[2], index: index, onlyImage: false}"></ng-container>
          </div>
          <ng-container *ngIf="index != undefined">
            <ng-container *ngTemplateOutlet="imageControl; context: {index: index}"></ng-container>
          </ng-container>
        </div>
      </div>
      <div *ngSwitchCase="'3_2'" class="layout-holder">
        <div class="preview">
          <div class="layout-item" [style.height]="'60%'" [style.width]="'50%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[0], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'60%'" [style.width]="'50%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[1], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'40%'" [style.width]="'100%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[2], index: index, onlyImage: false}"></ng-container>
          </div>
          <ng-container *ngIf="index != undefined">
            <ng-container *ngTemplateOutlet="imageControl; context: {index: index}"></ng-container>
          </ng-container>
        </div>
      </div>
      <div *ngSwitchCase="'3_3'" class="layout-holder">
        <div class="preview">
          <div class="layout-item" [style.height]="'50%'" [style.width]="'40%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[0], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'50%'" [style.width]="'60%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[1], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'50%'" [style.width]="'100%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[2], index: index, onlyImage: false}"></ng-container>
          </div>
          <ng-container *ngIf="index != undefined">
            <ng-container *ngTemplateOutlet="imageControl; context: {index: index}"></ng-container>
          </ng-container>
        </div>
      </div>
      <div *ngSwitchCase="'4_1'" class="layout-holder">
        <div class="preview">
          <div class="layout-item" [style.height]="'50%'" [style.width]="'40%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[0], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'50%'" [style.width]="'60%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[1], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'50%'" [style.width]="'60%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[2], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'50%'" [style.width]="'40%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[3], index: index, onlyImage: false}"></ng-container>
          </div>
          <ng-container *ngIf="index != undefined">
            <ng-container *ngTemplateOutlet="imageControl; context: {index: index}"></ng-container>
          </ng-container>
        </div>
      </div>
      <div *ngSwitchCase="'4_2'" class="layout-holder">
        <div class="preview">
          <div class="layout-item" [style.height]="'55%'" [style.width]="'60%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[0], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'55%'" [style.width]="'40%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[1], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'45%'" [style.width]="'45%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[2], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'45%'" [style.width]="'55%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[3], index: index, onlyImage: false}"></ng-container>
          </div>
          <ng-container *ngIf="index != undefined">
            <ng-container *ngTemplateOutlet="imageControl; context: {index: index}"></ng-container>
          </ng-container>
        </div>
      </div>
      <div *ngSwitchCase="'4_3'" class="layout-holder">
        <div class="preview">
          <div class="layout-item" [style.height]="'50%'" [style.width]="'60%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[0], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'50%'" [style.width]="'40%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[1], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'50%'" [style.width]="'40%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[2], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'50%'" [style.width]="'60%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[3], index: index, onlyImage: false}"></ng-container>
          </div>
          <ng-container *ngIf="index != undefined">
            <ng-container *ngTemplateOutlet="imageControl; context: {index: index}"></ng-container>
          </ng-container>
        </div>
      </div>
      <div *ngSwitchCase="'4_4'" class="layout-holder">
        <div class="preview">
          <div class="layout-item" [style.height]="'50%'" [style.width]="'100%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[0], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'50%'" [style.width]="'33.333%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[1], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'50%'" [style.width]="'33.333%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[2], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'50%'" [style.width]="'33.333%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[3], index: index, onlyImage: false}"></ng-container>
          </div>
          <ng-container *ngIf="index != undefined">
            <ng-container *ngTemplateOutlet="imageControl; context: {index: index}"></ng-container>
          </ng-container>
        </div>
      </div>
      <div *ngSwitchCase="'5_1'" class="layout-holder">
        <div class="preview">
          <div class="layout-item" [style.height]="'50%'" [style.width]="'33.333%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[0], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'50%'" [style.width]="'33.333%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[1], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'50%'" [style.width]="'33.333%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[2], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'50%'" [style.width]="'50%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[3], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'50%'" [style.width]="'50%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[4], index: index, onlyImage: false}"></ng-container>
          </div>
          <ng-container *ngIf="index != undefined">
            <ng-container *ngTemplateOutlet="imageControl; context: {index: index}"></ng-container>
          </ng-container>
        </div>
      </div>
      <div *ngSwitchCase="'5_2'" class="layout-holder">
        <div class="preview flex-column">
          <div class="layout-item" [style.height]="'60%'" [style.width]="'50%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[0], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'40%'" [style.width]="'50%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[1], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'33.333%'" [style.width]="'50%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[2], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'33.333%'" [style.width]="'50%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[3], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'33.333%'" [style.width]="'50%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[4], index: index, onlyImage: false}"></ng-container>
          </div>
          <ng-container *ngIf="index != undefined">
            <ng-container *ngTemplateOutlet="imageControl; context: {index: index}"></ng-container>
          </ng-container>
        </div>
      </div>
      <div *ngSwitchCase="'5_3'" class="layout-holder">
        <div class="preview">
          <div class="layout-item" [style.height]="'50%'" [style.width]="'50%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[0], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'50%'" [style.width]="'50%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[1], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'50%'" [style.width]="'33.333%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[2], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'50%'" [style.width]="'33.333%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[3], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'50%'" [style.width]="'33.333%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[4], index: index, onlyImage: false}"></ng-container>
          </div>
          <ng-container *ngIf="index != undefined">
            <ng-container *ngTemplateOutlet="imageControl; context: {index: index}"></ng-container>
          </ng-container>
        </div>
      </div>
      <div *ngSwitchCase="'6_1'" class="layout-holder">
        <div class="preview">
          <div class="layout-item" [style.height]="'50%'" [style.width]="'33.333%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[0], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'50%'" [style.width]="'33.333%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[1], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'50%'" [style.width]="'33.333%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[2], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'50%'" [style.width]="'33.333%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[3], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'50%'" [style.width]="'33.333%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[4], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'50%'" [style.width]="'33.333%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[5], index: index, onlyImage: false}"></ng-container>
          </div>
          <ng-container *ngIf="index != undefined">
            <ng-container *ngTemplateOutlet="imageControl; context: {index: index}"></ng-container>
          </ng-container>
        </div>
      </div>
      <div *ngSwitchCase="'6_2'" class="layout-holder">
        <div class="preview flex-column">
          <div class="layout-item" [style.height]="'100%'" [style.width]="'25%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[0], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'100%'" [style.width]="'25%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[1], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'25%'" [style.width]="'50%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[2], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'25%'" [style.width]="'50%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[3], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'25%'" [style.width]="'50%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[4], index: index, onlyImage: false}"></ng-container>
          </div>
          <div class="layout-item" [style.height]="'25%'" [style.width]="'50%'">
            <ng-container
              *ngTemplateOutlet="imageTemplate; context: {image: item.photoList[5], index: index, onlyImage: false}"></ng-container>
          </div>
          <ng-container *ngIf="index != undefined">
            <ng-container *ngTemplateOutlet="imageControl; context: {index: index}"></ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <span
      class="album-text">{{ index == 0 ? 'Fly leaf' : (index == -1 ? 'Cover' : (index == -2 ? 'Back cover' : index)) }}</span>
  </div>
</ng-template>

<ng-template let-item="item" let-index="index" #pageControlTemplate>
  <div class="page-controls" *ngIf="index % 2 == 0 && editing">
    <a href=""
       (click)="$event.preventDefault(); addPage(item)"
       class="rename">
      <fa-icon [icon]="faPlus"></fa-icon>
    </a>
    <a href=""
       (click)="$event.preventDefault(); deletePage(item)"
       class="rename">
      <fa-icon [icon]="faTrashAlt"></fa-icon>
    </a>
  </div>
</ng-template>

<div #printView [style.display]="'none'">
  <div class="book for-print" [ngClass]="[book?.orientation ? book?.orientation : '', editing ? 'editing' : '']">
    <div class="pages">
      <ng-container *ngTemplateOutlet="layoutTemplate; context: {item: this.book?.cover, index: -1}"></ng-container>
    </div>
  </div>
  <div class="book for-print" [ngClass]="[book?.orientation ? book?.orientation : '', editing ? 'editing' : '']"
       *ngFor="let page of book?.pages; let index = index">
    <div class="pages">
      <ng-container *ngTemplateOutlet="layoutTemplate; context: {item: page}"></ng-container>
    </div>
  </div>
  <div class="book for-print" [ngClass]="[book?.orientation ? book?.orientation : '', editing ? 'editing' : '']">
    <div class="pages">
      <ng-container *ngTemplateOutlet="layoutTemplate; context: {item: this.book?.backcover, index: -2}"></ng-container>
    </div>
  </div>
</div>

<main>
  <div class="modal-backdrop fade show"></div>
  <div class="modal fade bd-example-modal-xl show" tabindex="-1" role="dialog" data-backdrop="static"
       aria-modal="true" style="display: block;">
    <div class="modal-dialog modal-l" role="document">
      <div class="modal-content">
        <nav class="navbar navbar-expand">
          <div class="container">
            <div
              class="app-logo"
              [style.backgroundImage]="'url(\'/assets/img/pic_roc_logo.svg\')'"
              [style.marginBottom]="'16px'"
              [style.marginLeft]="'16px'"
            ></div>
            <p class="title">Hi, {{ CredentialsService.facebookSocialUser ? CredentialsService.facebookSocialUser.firstName : 'there'}}</p>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <div class="profile-picture" [style.backgroundImage]="CredentialsService.profilePictureUrl"></div>
              <ul class="navbar-nav">
                <li class="nav-item dropdown" dropdown>
                  <a id="navbarDropdown" class="nav-link dropdown-toggle" dropdownToggle href="" role="button"
                     (click)="$event.preventDefault()"
                     data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <fa-icon [icon]="faChevronDown"></fa-icon>
                    <span class="caret"></span>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown" *dropdownMenu>
                    <a class="dropdown-item" href="" [routerLink]="'/profile'">Home</a>
                    <a class="dropdown-item" href="">Orders</a>
                    <a class="dropdown-item" href="" (click)="$event.preventDefault(); credentialsService.signOut().subscribe()">Logout</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <hr>
        <div class="modal-body mb-4" *ngIf="orderDetails">
          <div class="order-number mb-4">
            Your order number <br>
            <span class="number">{{orderDetails?.uuid}}</span>
          </div>
          <div class="row">
            <div class="col-md-6 col-12">
              <p class="paid-at">
                <strong>Ordered on:</strong> {{orderDetails?.paid_at | date: 'yyyy.MM.dd. HH:mm'}} <br>
              </p>
            </div>
            <div class="col-md-6 col-12">
              <p>
                <strong>Ordered value:</strong> ${{orderDetails?.total_price}}
              </p>
            </div>
          </div>
        </div>
        <div [style.padding]="'1em'" *ngIf="error">
          <h3>An error occured</h3>
          <p>Your order id seems to be invalid.</p>
        </div>
      </div>
    </div>
  </div>
</main>
<footer class="footer footer--modal-footer footer--auto-height bg-white">
  <div class="container">
    <div class="row button-row">
      <div class="col-lg-6 col-12">
        <button type="button" class="btn btn--gradient btn--full-width" (click)="createBook()">
          Create a new photobook!
        </button>
      </div>
      <div class="col-lg-6 col-12 text-center">
        <a href="#" [routerLink]="['/profile/books']" [style.marginTop]="'0.3em'" [style.display]="'inline-block'">Back to albums</a>
      </div>
    </div>
  </div>
</footer>
<ng-container *ngIf="showDialog">
  <app-ok-dialog
    title="Wait! There is a save for your initial book!"
    body="Hold on, you have a photo book previously created with PicRoc.<br>Would you like to continue editing it?"
    positiveButtonText="YES, TAKE ME TO THE DRAFT PHOTO BOOK"
    negativeButtonText="NO, I’D LIKE TO START A NEW BOOK"
    (closeDialog)="(!$event ? CreateBookComponent.eraseSave() : undefined); router.navigate(['/create-book']).then()">
  </app-ok-dialog>
</ng-container>

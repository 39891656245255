<main>
  <div class="modal-backdrop fade show"></div>
  <div class="modal fade bd-example-modal-xl show" tabindex="-1" role="dialog" data-backdrop="static"
       aria-modal="true" style="display: block;">
    <div class="modal-dialog modal-l" role="document">
      <div class="modal-content">
        <div class="modal-header my-4">
          <fa-icon class="back mobile" [icon]="faChevronLeft" (click)="goBackToBasket()"></fa-icon>
          <span class="title mx-auto">Checkout</span>
        </div>
        <div class="modal-tab">
          <a href="#" [ngClass]="{'active': tab == 'shipping'}"
             (click)="$event.preventDefault(); form.valid ? tab = 'shipping' : ''">
            Shipping
          </a>
          <a href="#" [ngClass]="{'active': tab == 'payment', 'disabled': form.invalid}"
             (click)="$event.preventDefault(); form.valid ? tab = 'payment': ''">
            Payment
          </a>
        </div>
        <ng-container *ngIf="tab == 'shipping'">
          <div class="d-flex flex-wrap" [formGroup]="form">
            <div class="col-md-6 col-12">
              <div class="modal-body">
                <h3 class="mb-2">Shipping details</h3>
              </div>
              <div class="modal-separator">
                <h6 class="modal-title">Contact information</h6>
              </div>
              <div class="modal-body">
                <p>Please give us an email address that we can reach you on.</p>
                <div>
                  <div class="form-group">
                    <label for="shippingEmail">E-mail*</label>
                    <input type="text" class="form-control" id="shippingEmail" formControlName="email"
                           placeholder="name@mydomain.com">
                  </div>
                </div>
              </div>
              <div class="modal-separator">
                <h6 class="modal-title">Shipping address</h6>
              </div>
              <div class="modal-body">
                <div>
                  <div class="form-group">
                    <label for="shippingFirstName">First name*</label>
                    <input type="text" class="form-control" id="shippingFirstName" formControlName="firstName">
                  </div>
                  <div class="form-group">
                    <label for="shippingLastName">Last name*</label>
                    <input type="text" class="form-control" id="shippingLastName" formControlName="lastName">
                  </div>
                  <div class="form-group">
                    <label for="shippingCompany">Company</label>
                    <input type="text" class="form-control" id="shippingCompany" formControlName="company">
                  </div>
                  <div [style.display]="!manualAddressInput ? 'block' : 'none'">
                    <div class="form-group">
                      <label for="shippingAddress">Search your address</label>
                      <input class="form-control mb-1 google-icon" id="shippingAddress"
                             #placeAutocomplete autocomplete="off"/>
                      <a href="#" class="text-right d-block"
                         (click)="$event.preventDefault(); manualAddressInput = true">Manually
                        add address</a>
                    </div>
                  </div>
                  <div [style.display]="manualAddressInput ? 'block' : 'none'">
                    <a href="#" class="text-right d-block"
                       (click)="$event.preventDefault(); manualAddressInput = false">Use
                      Google Address autocomplete</a>
                    <div class="form-group">
                      <label for="countryCode">Country*</label>
                      <select class="form-control" id="countryCode" formControlName="countryCode">
                        <option [value]="country.countryCode"
                                *ngFor="let country of countryCodes">{{country.countryName}}</option>
                      </select>
                      <fa-icon [icon]="faChevronDown"></fa-icon>
                    </div>
                    <div class="form-group">
                      <label for="zip">ZIP*</label>
                      <input type="text" class="form-control" id="zip" formControlName="zip">
                    </div>
                    <div class="form-group">
                      <label for="state">State*</label>
                      <input type="text" class="form-control" id="state" formControlName="state">
                    </div>
                    <div class="form-group">
                      <label for="city">City*</label>
                      <input type="text" class="form-control" id="city" formControlName="city">
                    </div>
                    <div class="form-group">
                      <label for="address">Address*</label>
                      <input type="text" class="form-control" id="address" formControlName="address">
                    </div>
                  </div>
                  <div class="custom-control custom-checkbox mb-3">
                    <input type="checkbox" class="custom-control-input" id="customCheck0"
                           formControlName="differentInvoiceAddress">
                    <label class="custom-control-label small" for="customCheck0">Use different billing address</label>
                  </div>
                </div>
              </div>
              <div [style.display]="form.controls.differentInvoiceAddress.value ? 'block' : 'none'">
                <div class="modal-separator">
                  <h6 class="modal-title">Invoice address</h6>
                </div>
                <div class="modal-body">
                  <div>
                    <div class="form-group">
                      <label for="invoiceCountryCode">Invoice country*</label>
                      <select class="form-control" id="invoiceCountryCode" formControlName="invoiceCountryCode">
                        <option [value]="country.countryName"
                                *ngFor="let country of countryCodes">{{country.countryName}}</option>
                      </select>
                      <fa-icon [icon]="faChevronDown"></fa-icon>
                    </div>
                    <div class="form-group">
                      <label for="invoiceZip">Invoice ZIP*</label>
                      <input type="text" class="form-control" id="invoiceZip" formControlName="invoiceZip">
                    </div>
                    <div class="form-group">
                      <label for="invoiceState">Invoice State*</label>
                      <input type="text" class="form-control" id="invoiceState" formControlName="invoiceState">
                    </div>
                    <div class="form-group">
                      <label for="invoiceCity">Invoice City*</label>
                      <input type="text" class="form-control" id="invoiceCity" formControlName="invoiceCity">
                    </div>
                    <div class="form-group">
                      <label for="invoiceAddress">Invoice Address*</label>
                      <input type="text" class="form-control" id="invoiceAddress" formControlName="invoiceAddress">
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-body">
                <div>
                  <div class="form-group">
                    <label>Shipping*</label><br>
                    <div class="form-check form-check-inline"
                         *ngFor="let shippingType of configureData?.shippingTypes; let i = index">
                      <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio{{i}}" class="custom-control-input"
                               formControlName="shippingType" [value]="shippingType.id">
                        <label class="custom-control-label" for="customRadio{{i}}">{{shippingType.name}}</label>
                      </div>
                    </div>
                  </div>
                  <div class="rounded-border--blue p-3" *ngIf="form.controls.shippingType.value">
                    <p class="mb-0">
                      Shipping cost: <strong>${{shippingCost}}</strong><br>
                      Shipping tme: <strong>{{shippingTime}}</strong> working days
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="modal-separator mobile">
                <h6 class="modal-title">Order details</h6>
              </div>
              <div class="modal-body">
                <h3 class="mb-2 desktop">Order details</h3>
                <p class="d-flex justify-content-between"><span>Subtotal</span>
                  <strong>${{(finalPrice?.originalPrice - finalPrice?.shippingPrice) | number:'1.0-2'}}</strong></p>
                <ng-container *ngIf="form.controls.shippingType.value">
                  <p class="d-flex justify-content-between"><span>Shipping cost</span>
                    <strong>${{finalPrice?.shippingPrice | number:'1.0-2'}}</strong></p>
                  <p class="d-flex justify-content-between" *ngIf="finalPrice?.discountPrice > 0"><span>Discount</span>
                    <strong>(-{{finalPrice.discountPercent}}%)
                      -${{finalPrice?.discountPrice | number: '1.0-2'}}</strong>
                  </p>
                  <hr>
                  <p class="d-flex mb-0 justify-content-between"><span>Total</span>
                    <strong>${{finalPrice?.totalPrice | number:'1.0-2'}}</strong>
                  </p>
                  <p class="smaller md-4">including tax & shipping</p>
                </ng-container>
              </div>
            </div>
            <div class="col-12">
              <hr>
              <div class="modal-body">
                <div class="custom-control custom-checkbox mb-2">
                  <input type="checkbox" class="custom-control-input" id="customCheck1" formControlName="acceptTerms">
                  <label class="custom-control-label small" for="customCheck1">
                    I accept and agree with the terms and conditions
                  </label>
                </div>
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="customCheck2" formControlName="reviewedBooks">
                  <label class="custom-control-label small" for="customCheck2">
                    I have reviewed my book(s) and understand that my book(s) will be printed as shown in the editor
                  </label>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div [ngClass]="tab == 'payment' ? 'd-flex flex-wrap' : 'd-none'">
          <div class="col-md-6 col-12">
            <div class="modal-body" [style.padding]="'2em'">
              <h3 class="mb-4">Order details</h3>
              <p class="mb-1"><strong>Billing & shipping address</strong></p>
              <p>
                {{form.controls.address.value}}<br>
                {{form.controls.state.value}}<br>
                {{form.controls.zip.value}} {{form.controls.city.value}}<br>
                {{form.controls.countryCode.value}}<br>
              </p>
              <hr>
              <p class="d-flex justify-content-between mb-0"><span>Total</span>
                <strong>${{finalPrice?.totalPrice | number:'1.0-2'}}</strong></p>
              <p class="smaller md-4">including tax & shipping</p>
              <hr>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="modal-body" [style.padding]="'2em'">
              <div>
                <p>{{ stripeError }}</p>
                <div class="form-group">
                  <label for="cardNumberElement">Card number</label>
                  <div id="cardNumberElement"></div>
                </div>
                <div class="form-group">
                  <label for="cardExpiryDate">Card expiry date</label>
                  <div id="cardExpiryDate"></div>
                </div>
                <div class="form-group">
                  <label for="paymentCVC">CCV/CVV/CID</label>
                  <div id="paymentCVC"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade bd-example-modal-xl show" tabindex="-1" role="dialog" data-backdrop="static"
       aria-modal="true" style="display: block;" *ngIf="checkoutResponse" [ngClass]="{'': checkoutResponse }">
    <div class="modal-dialog modal-l" role="document">
      <div class="modal-content checked-out">
        <div class="modal-header">
          <div class="logo"></div>
        </div>
        <hr>
        <div class="modal-body">
          <h2 class="text-center">Thank you</h2>
          <h4 class="mb-4 text-center">Your order was successful.</h4>
          <div class="order-number mb-4">
            Your order number <br>
            <span class="number">{{checkoutResponse.uuid}}</span>
          </div>
          <p>
            We've sent you an email with your oder details.<br>
            You shold see it in your inbox shortly at <a
            href="mailto:{{checkoutResponse.email}}">{{checkoutResponse.email}}</a>.
            Please be sure to check your spam folder and add us to your safe sender list, so all mails reach
            you.
          </p>
        </div>
        <div class="modal-body background--grey pb-4">
          <img [style.width]="'100%'" class="img-fluid mb-2" [src]="form.controls.book.value.cover.photoList[0].url" alt="Album cover">
          <h4 [style.marginTop]="'30px'"><strong>Invite your friends and save
            <span class="font-black text-blue">40%</span> on your next order!</strong></h4>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium adipisci alias animi at,
            blanditiis, consequatur corporis culpa dolorem eius fuga incidunt iure natus nulla omnis quidem
            reiciendis rerum, sed temporibus.</p>
          <div>
            <div class="form-group" *ngFor="let friendControl of friendEmailFormArray.controls; let index = index;">
              <label for="friendEmail_{{index}}">Your friend's email address:</label>
              <input [formControl]="$any(friendControl)" type="text" class="form-control" id="friendEmail_{{index}}">
            </div>
            <a href="#" (click)="$event.preventDefault(); addFriendFormControl()">
              <fa-icon [icon]="faPlusSquare"></fa-icon>
              Add one more friend</a>
          </div>
        </div>
      </div>

    </div>
  </div>
  <ng-container *ngIf="showErrorDialog">
    <app-ok-dialog
      title="An error occured"
      body="There was an error during checkout, {{checkoutErrorMessage ?
      ('please read the additional informations below.<br/>' + checkoutErrorMessage) :
      'please try again later or contact helpdesk.'}}"
      (closeDialog)="this.showErrorDialog = false;">
    </app-ok-dialog>
  </ng-container>
</main>
<footer class="footer footer--modal-footer bg-white" [ngClass]="{'checked-out': checkoutResponse }">
  <ng-container *ngIf="!checkoutResponse">
    <div class="container">
      <div class="row">
        <div class="col-6">
          <a href="#" (click)="$event.preventDefault(); goBackToBasket()" class="text-uppercase"
             style="width: 100%; height: 100%; display: inline-block; text-align: center;">Back</a>
        </div>
        <div class="col-6">
          <button type="button" class="btn btn--gradient btn--full-width" [disabled]="form.invalid"
                  *ngIf="tab == 'shipping'" (click)="tab = 'payment'">
            Secure payment
          </button>
          <button type="button" class="btn btn--gradient btn--full-width" [disabled]="form.invalid"
                  *ngIf="tab == 'payment'" (click)="checkout()">
            Checkout
          </button>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="checkoutResponse">
    <div class="container">
      <div class="row button-row">
        <div class="col-md-6 col-12">
          <button
            type="button"
            class="btn btn--gradient btn--full-width"
            [disabled]="friendEmailFormArray.invalid"
            (click)="shareDiscount()"
          >
            Share for your discount
          </button>
        </div>
        <div class="col-md-6 col-12 text-center">
          <a href="#" [routerLink]="['/order/' + checkoutResponse.uuid]" [replaceUrl]="true"
             [style.marginTop]="'0.3em'"  [style.display]="'inline-block'">View my order</a>
        </div>
      </div>
    </div>
  </ng-container>
</footer>

import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {LoginComponent} from './components/login/login.component';
import {ProfileComponent} from './components/profile/profile.component';
import {TokenGuardService} from './guards/token-guard.service';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {CreateBookComponent} from './components/create-book/create-book.component';
import {BookEditorComponent} from './components/book-editor/book-editor.component';
import {ConfigureBookDialogComponent} from './components/configure-book-dialog/configure-book-dialog.component';
import {CheckoutComponent} from './components/checkout/checkout.component';
import {BasketComponent} from './components/basket/basket.component';
import {OrderComponent} from './components/order/order.component';

const routes: Routes = [
  {
    path: 'order/:orderUuid',
    component: OrderComponent,
    data: {
      title: 'Order details',
      description: ''
    }
  },
  {
    path: 'checkout/:bookId',
    component: CheckoutComponent,
    data: {
      title: 'Checkout',
      description: ''
    },
    canActivate: [TokenGuardService]
  },
  {
    path: 'basket/:bookId',
    component: BasketComponent,
    data: {
      title: 'Basket',
      description: ''
    },
    canActivate: [TokenGuardService]
  },
  {
    path: 'book/:bookId/:tab/order',
    component: ConfigureBookDialogComponent,
    data: {
      title: 'Order book',
      description: ''
    },
    canActivate: [TokenGuardService]
  },
  {
    path: 'book/:bookId/:tab',
    component: BookEditorComponent,
    data: {
      title: 'Edit book',
      description: ''
    },
    canActivate: [TokenGuardService]
  },
  {
    path: 'create-book/instagram-login-failed',
    component: CreateBookComponent,
    data: {
      title: 'Create book',
      description: ''
    },
    canActivate: [TokenGuardService]
  },
  {
    path: 'create-book/instagram-login/:instagramToken',
    component: CreateBookComponent,
    data: {
      title: 'Create book',
      description: ''
    },
    canActivate: [TokenGuardService]
  },
  {
    path: 'create-book',
    component: CreateBookComponent,
    data: {
      title: 'Create book',
      description: ''
    },
    canActivate: [TokenGuardService]
  },
  {
    path: 'profile/:tab',
    component: ProfileComponent,
    data: {
      title: 'Profil',
      description: ''
    },
    canActivate: [TokenGuardService]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    data: {
      title: 'Profil',
      description: ''
    },
    canActivate: [TokenGuardService]
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Bejelentkezés',
      description: ''
    }
  },
  {
    path: 'dashboard',
    component: ProfileComponent,
    data: {
      title: 'Photobook dashboard',
      description: ''
    },
    canActivate: [TokenGuardService]
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '**',
    component: NotFoundComponent,
    data: {
      title: 'Photobook - Az oldal nem található',
      description: 'Az oldal nem található. Biztos hogy jól írta be a címet?'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

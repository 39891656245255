import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-layout-change',
  templateUrl: './layout-change.component.html',
  styleUrls: ['./layout-change.component.scss']
})
export class LayoutChangeComponent implements OnInit {
  @Output() closeDialog = new EventEmitter<any>();
  @Output() layoutChanged = new EventEmitter<'1_1' | '2_1' | '2_2' | '3_1' | '3_2' | '3_3' | '4_1' | '4_2' | '4_3' | '4_4' | '5_1' | '5_2' | '5_3' | '6_1' | '6_2'>();
  @Input() layoutFormat: string;
  selectedLayout: '1_1' | '2_1' | '2_2' | '3_1' | '3_2' | '3_3' | '4_1' | '4_2' | '4_3' | '4_4' | '5_1' | '5_2' | '5_3' | '6_1' | '6_2';
  constructor() { }

  ngOnInit(): void {
  }

}

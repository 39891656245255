import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CredentialsService} from '../../services/credentials.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
  formGroup: FormGroup = this.formBuilder.group({
    acceptTerms: [null, [Validators.required, Validators.requiredTrue]],
    promotions: [false],
  });

  constructor(private formBuilder: FormBuilder, private credentialsService: CredentialsService, private router: Router) {
  }

  ngOnInit(): void {
  }

  signInWithFacebook(): void {
    this.credentialsService.signInWithFacebook().subscribe(() => {
      this.credentialsService.sendMarketingConsent(this.formGroup.controls.promotions.value).subscribe(() => {
        this.router.navigate(['/'], { replaceUrl: true }).then();
      });
    });
  }
}

<div class="modal-backdrop fade show"></div>
<div class="modal fade bd-example-modal-xl show" tabindex="-1" role="dialog" data-backdrop="static"
     aria-modal="true" style="display: block;" (click)="$event.stopPropagation();">
  <div class="modal-dialog modal-dialog-centered" role="document" (click)="$event.stopPropagation();">
    <div class="modal-content">
      <div class="modal-body" [style.padding]="calculatePadding()">
        <div class="image-preview"
             #imageHolder
             (mousemove)="mouseMove($event)"
             (mousedown)="imageDragging = true"
             (mouseup)="imageDragging = false"
             (touchstart)="imageDragging = true; oldTouchX = undefined; oldTouchY = undefined;"
             (touchend)="imageDragging = false; oldTouchX = undefined; oldTouchY = undefined;"
             (touchmove)="touchMove($event)">
          <img src="{{newPhoto ? newPhoto.url : data.image.url}}"
               (dragstart)="$event.preventDefault()"
               [style.transform]="getImageProperty('rotate', imageHolder) + ' ' + getImageProperty('backgroundPositionX', imageHolder) + ' ' + getImageProperty('backgroundPositionY', imageHolder)"
               [style.width]="getImageProperty('size') + '%'">
          <span class="text" *ngIf="getImageProperty('fontText')?.length > 0"
                #textContainer
                [ngClass]="{'editing': fontEdit}"
                [style.color]="getImageProperty('fontColor') ? getImageProperty('fontColor') : '#FFFFFF'"
                [style.left]="getImageProperty('fontPositionX')"
                [style.top]="getImageProperty('fontPositionY')"
                [style.lineHeight]="getImageProperty('fontSize')"
                [style.fontSize]="getImageProperty('fontSize')"
                [innerHTML]="getImageProperty('fontText')">
          </span>
          <div id="horizontal-guide" *ngIf="showHorizontalGuide"></div>
          <div id="vertical-guide" *ngIf="showVerticalGuide"></div>
          <button (click)="editText()"
                  *ngIf="fontEdit"
                  [style.left]="'calc(' + getImageProperty('fontPositionX') + ' - 2.2em)'"
                  [style.top]="'calc(' + getImageProperty('fontPositionY') + ' + 0.5em)'">
            <fa-icon [icon]="faPencilAlt"></fa-icon>
          </button>
        </div>
      </div>
      <div class="image-action-buttons" [ngClass]="{'selected': fontEdit}">
        <div class="image-slider">
          <a href="" (click)="$event.preventDefault();
           fontEdit ? (newImageData.fontSize = newImageData.fontSize - 0.5) : (newImageData.size = newImageData.size - 0.5)">
            <fa-icon [icon]="faMinus"></fa-icon>
          </a>
          <input type="range" step="0.25" max="100" min="0" [(ngModel)]="newImageData.fontSize"
                 class="photobook-range form-control-range" *ngIf="fontEdit">
          <input type="range" step="0.5" max="600" min="1" [(ngModel)]="newImageData.size"
                 class="photobook-range form-control-range" *ngIf="!fontEdit">
          <a href="" (click)="$event.preventDefault();
          fontEdit ? (newImageData.fontSize = newImageData.fontSize + 0.5) : (newImageData.size = newImageData.size + 0.5)">
            <fa-icon [icon]="faPlus"></fa-icon>
          </a>
        </div>
        <a href="" (click)="$event.preventDefault(); fontEdit = !fontEdit" class="font-edit-button">
          <fa-icon [icon]="faFont"></fa-icon>
        </a>
        <a href="" (click)="$event.preventDefault(); newImageData.rotate = (newImageData.rotate - 90) % 360;
        newImageData.backgroundPositionX = 0; newImageData.backgroundPositionY = 0;">
          <fa-icon [icon]="faUndo"></fa-icon>
        </a>
      </div>
      <div class="font-color-holder" *ngIf="fontEdit">
        <div class="color-button" [style.backgroundColor]="'#FFFFFF'"
             (click)="newImageData.fontColor = '#FFFFFF'"></div>
        <div class="color-button" [style.backgroundColor]="'#000000'"
             (click)="newImageData.fontColor = '#000000'"></div>
        <div class="color-button" [style.backgroundColor]="'#f9b256'"
             (click)="newImageData.fontColor = '#f9b256'"></div>
        <div class="color-button" [style.backgroundColor]="'#3faf6c'"
             (click)="newImageData.fontColor = '#3faf6c'"></div>
      </div>
    </div>
  </div>
</div>
<footer class="footer footer--modal-footer bg-white image-modal-footer">
  <div class="row justify-content-center">
    <a class="prevent-select image-button-row" href="" (click)="$event.preventDefault(); showChangePhotoDialog();">
      <fa-icon [icon]="faExchangeAlt"></fa-icon>
      Change photo
    </a>
    <a class="prevent-select image-button-row" href="" *ngIf="!data.cover"
       (click)="$event.preventDefault(); useAsCover.emit(newPhoto ? newPhoto.url : data.image.url)">
      <fa-icon [icon]="faImage"></fa-icon>
      Use as front cover
    </a>
    <a class="prevent-select image-button-row" href="" *ngIf="!data.backcover"
       (click)="$event.preventDefault(); useAsBackCover.emit(newPhoto ? newPhoto.url : data.image.url)">
      <fa-icon [icon]="fasImage"></fa-icon>
      Use as back cover
    </a>
    <a class="prevent-select image-button-row" href="" *ngIf="!data.cover && !data.backcover"
       (click)="$event.preventDefault(); deletePhoto.emit({ pageIndex: pageIndex, imageIndex: data.imageIndex }); closeDialog.emit();">
      <fa-icon [icon]="faTrashAlt"></fa-icon>
      Delete photo
    </a>
  </div>
  <div class="row button-row">
    <button type="button" class="btn btn--outline btn--full-width" (click)="closeDialog.emit()" [style.border]="'none'">
      Cancel
    </button>
    <button type="button" class="btn btn--gradient btn--full-width" (click)="saveLayout()">
      Save
    </button>
  </div>
</footer>
<ng-container *ngIf="showInputDialog">
  <app-input-dialog
    [initialText]="this.newImageData.fontText"
    [maxLength]="50"
    [multilineText]="true"
    positiveButtonText="Set text"
    negativeButtonText="Cancel"
    title="Set your text for the image"
    (closeDialog)="closeInputDialog($event)"></app-input-dialog>
</ng-container>
<ng-container *ngIf="changePhotoDialog">
  <app-change-photo-dialog
    [book]="book"
    [pageIndex]="pageIndex"
    [imageIndex]="data.imageIndex"
    [originalPhoto]="data.image"
    (photoChanged)="photoChanged($event)"
    (closeDialog)="changePhotoDialog = false;">
  </app-change-photo-dialog>
</ng-container>

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons';
import {Book, Photo, PhotobookImage} from '../../data/book';
import {PhotoService} from '../../services/photo.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-change-photo-dialog',
  templateUrl: './change-photo-dialog.component.html',
  styleUrls: ['./change-photo-dialog.component.scss']
})
export class ChangePhotoDialogComponent implements OnInit {
  faChevronDown = faChevronDown;
  @Output() photoChanged: EventEmitter<Photo> = new EventEmitter<Photo>();
  @Output() closeDialog: EventEmitter<any> = new EventEmitter<any>();
  private _originalPhoto: PhotobookImage;
  @Input() imageIndex: number;
  @Input() set originalPhoto(value: PhotobookImage) {
    this._originalPhoto = value;
  }
  get originalPhoto(): PhotobookImage {
    return this._originalPhoto;
  }
  selectedPhoto: Photo;
  imageList: Photo[];
  @Input() book: Book;
  @Input() pageIndex: number;
  private _sortDirection: 'asc' | 'desc' = localStorage.getItem('sortDirection') as any ?? 'desc';
  get sortDirection(): 'asc' | 'desc' {
    return this._sortDirection;
  }

  set sortDirection(value: 'asc' | 'desc') {
    this._sortDirection = value;
    localStorage.setItem('sortDirection', value);
    this.imageList = this.imageList.sort((a, b) => {
      return this.sortDirection === 'asc' ? a.createdTime.getTime() - b.createdTime.getTime() :
        b.createdTime.getTime() - a.createdTime.getTime();
    });
  }

  constructor(private photoService: PhotoService, private router: Router, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.photoService.getImages(this.book.albumIdList,
      this.book.albumIdList.find(albumId => albumId.match(/^20[0-9]{2}$/)) !== undefined,
      this.book.id, this.pageIndex, this.imageIndex)
      .subscribe(response => {
        this.imageList = response;
        this.sortDirection = this.sortDirection;
      });
  }

  selectImage(image: Photo): void {
    this.selectedPhoto = this.originalPhoto.id !== image.id ? image : undefined;
  }

  handleCloseDialog(): void {
    this.router.navigate([],
      {
        relativeTo: this.activatedRoute,
        queryParams: {
          showEditPage: undefined
        },
        queryParamsHandling: 'merge'
      }).then(() => {
        this.closeDialog.emit();
      }
    );
  }

  handlePhotoChanged(): void {
    this.router.navigate([],
      {
        relativeTo: this.activatedRoute,
        queryParams: {
          showEditPage: undefined
        },
        queryParamsHandling: 'merge'
      }).then(() => {
        this.photoChanged.emit(this.selectedPhoto);
      }
    );
  }
}

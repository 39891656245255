import {HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {CredentialsService} from './credentials.service';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';

@Injectable()
export class PhotobookInterceptorService implements HttpInterceptor {
  constructor(private router: Router, private credentialsService: CredentialsService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (CredentialsService.facebookSocialUser) {
      if (req.url.indexOf('v1/tokens') === -1) {
        req = req.clone({
          params: (req.params ? req.params : new HttpParams())
            .set('FbToken', CredentialsService.facebookSocialUser.authToken)
            .set('fb_user_id', CredentialsService.facebookSocialUser.id)
            .set('InstaToken', CredentialsService.instagramToken)
        });
      }
    }
    return next.handle(req).pipe(
      catchError((error) => {
          if (error.status === 481) {
            return this.credentialsService.signInWithFacebook()
              .pipe(
                mergeMap((response) => {
                  req = req.clone({
                    params: (req.params ? req.params : new HttpParams())
                      .set('FbToken', CredentialsService.facebookSocialUser.authToken)
                      .set('fb_user_id', CredentialsService.facebookSocialUser.id)
                      .set('InstaToken', CredentialsService.instagramToken)
                  });
                  return next.handle(req);
                })
              );
          } else {
            throw throwError(error);
          }
        }
      ));
  }
}

<div class="login-container">
  <div class="login-card" [formGroup]="formGroup">
    <img src="./assets/img/pic_roc_logo.svg" height="40">
    <h1>Hello!</h1>
    <h6>
      <span [style.fontWeight]="'bolder'">Sign-up or log in with your Facebook account</span><br/>
      and create your photo book<br/>
      in just a few minutes
    </h6>
    <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" id="customCheck1" formControlName="acceptTerms">
      <label class="custom-control-label small" for="customCheck1">
        I agree to the Terms and conditions
      </label>
    </div>
    <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" id="customCheck2" formControlName="promotions">
      <label class="custom-control-label small" for="customCheck2">
        I agree to receive information and promotional offers from PicRoc
      </label>
    </div>
    <button class="facebook-button desktop" (click)="signInWithFacebook()" [disabled]="formGroup.invalid"></button>
  </div>
</div>
<button class="facebook-button mobile" (click)="signInWithFacebook()" [disabled]="formGroup.invalid"></button>
<div id="book-image"></div>

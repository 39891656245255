import {Component, EventEmitter, Input, Output} from '@angular/core';
import {faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';
import {OrderService} from '../../services/order.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Book} from '../../data/book';
import {OrderFeature} from '../../data/order';
import {BookService} from '../../services/book.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-configure-book-dialog',
  templateUrl: './configure-book-dialog.component.html',
  styleUrls: ['./configure-book-dialog.component.scss']
})
export class ConfigureBookDialogComponent {
  isNaN: any = isNaN;
  Number: any = Number;
  faPlus: any = faPlus;
  faMinus: any = faMinus;
  formGroup: FormGroup = this.formBuilder.group({
    numberOfLargeOrder: [0, Validators.pattern(/^(0|[1-9][0-9]*)$/)],
    numberOfSmallOrder: [0, Validators.pattern(/^(0|[1-9][0-9]*)$/)],
    features: new FormArray([])
  });

  @Output() closeDialog = new EventEmitter<any>();
  @Input() book: Book;
  @Input() printHTML: string[];

  priceOfSmallBook = 0;
  priceOfLargeBook = 0;

  get selectedBooks(): string {
    const orderedBooks = [];
    if (this.formGroup.controls.numberOfLargeOrder.value > 0) {
      orderedBooks.push(`${this.formGroup.controls.numberOfLargeOrder.value} large (8" x 8")`);
    }
    if (this.formGroup.controls.numberOfSmallOrder.value > 0) {
      orderedBooks.push(`${this.formGroup.controls.numberOfSmallOrder.value} small (6" x 6")`);
    }
    return orderedBooks.length > 0 ? orderedBooks.join(', ') : '-';
  }

  get selectedExtras(): string {
    const features = (this.formGroup.controls.features as FormArray).controls.map((control, index) => {
      return control.value ? this.orderFeatures[index].name : null;
    }).filter(item => item);
    return features.length > 0 ? features.join(', ') : '-';
  }

  get subtotal(): number {
    if (this.formGroup.valid) {
      let subtotal = this.formGroup.controls.numberOfLargeOrder.value * this.priceOfLargeBook +
        this.formGroup.controls.numberOfSmallOrder.value * this.priceOfSmallBook;
      let extraFee = 0;
      (this.formGroup.controls.features as FormArray).controls.forEach((control, index) => {
        if (control.value) {
          extraFee += this.orderFeatures[index].price;
        }
      });
      subtotal += extraFee * (this.formGroup.controls.numberOfLargeOrder.value + this.formGroup.controls.numberOfSmallOrder.value);
      return subtotal;
    } else {
      return null;
    }
  }

  @Input() numberOfPages: number;
  orderFeatures: OrderFeature[] = [];

  constructor(private service: OrderService, private formBuilder: FormBuilder, private bookService: BookService, private router: Router) {
    this.service.getConfigureData().subscribe(response => {
      if (response.bookOrderData.small) {
        this.priceOfSmallBook = response.bookOrderData.small[this.book.orientation].basePrice + (this.book.pages.length - 24) *
          response.bookOrderData.small[this.book.orientation].additionalPagePrice;
      }
      if (response.bookOrderData.large) {
        this.priceOfLargeBook = response.bookOrderData.large[this.book.orientation].basePrice + (this.book.pages.length - 24) *
          response.bookOrderData.large[this.book.orientation].additionalPagePrice;
      }
      response.bookOrderData.featureList.forEach(() => {
        (this.formGroup.controls.features as FormArray).push(new FormControl(false));
      });
      this.orderFeatures = response.bookOrderData.featureList;
    });
  }

  increaseNumberOfLargeOrder(): void {
    this.formGroup.controls.numberOfLargeOrder.setValue(this.formGroup.controls.numberOfLargeOrder.value + 1);
  }

  decreaseNumberOfLargeOrder(): void {
    this.formGroup.controls.numberOfLargeOrder.setValue(this.formGroup.controls.numberOfLargeOrder.value - 1);
  }

  increaseNumberOfSmallOrder(): void {
    this.formGroup.controls.numberOfSmallOrder.setValue(this.formGroup.controls.numberOfSmallOrder.value + 1);
  }

  decreaseNumberOfSmallOrder(): void {
    this.formGroup.controls.numberOfSmallOrder.setValue(this.formGroup.controls.numberOfSmallOrder.value - 1);
  }

  preventNegativeValue(value: number): number {
    if (value < 0) {
      return 0;
    } else {
      return value;
    }
  }

  configureBook(): void {
    const request = JSON.parse(JSON.stringify(this.book));
    request.printHTML = this.printHTML;
    request.status = 'configured';
    request.orderInfo = {
      featureList: (this.formGroup.controls.features as FormArray).controls.map((control, index) => {
        return control.value ? this.orderFeatures[index].id : null;
      }).filter(item => item),
      largeNumber: this.formGroup.controls.numberOfLargeOrder.value,
      smallNumber: this.formGroup.controls.numberOfSmallOrder.value,
      shippingType: null,
      couponCode: null
    };
    this.bookService.saveBook(request).subscribe((response) => {
      this.router.navigate(['/basket/' + response.id], {replaceUrl: true}).then();
    });
  }
}

<div class="reorderable-list-container" cdkDropListGroup [cdkDropListGroupDisabled]="disabled" [ngClass]="disabled ? 'disabled' : ''">
  <div cdkDropList [cdkDropListEnterPredicate]="dropListEnterPredicate" (cdkDropListDropped)="dropListDropped()"></div>
  <div cdkDropList *ngFor="let item of items; let i = index;"
       [cdkDropListEnterPredicate]="dropListEnterPredicate" (cdkDropListDropped)="dropListDropped()">
    <div cdkDrag class="reorderable-list-box" (cdkDragMoved)="dragMoved($event);"
         (cdkDragStarted)="dragStarted($event)" (cdkDragEnded)="dragEnded($event)">
      <ng-container *ngTemplateOutlet="itemTemplate ? itemTemplate : defaultTemplate; context: {item: item, index: i}"></ng-container>
    </div>
    <ng-container *ngIf="!dragInProgress">
      <ng-container *ngTemplateOutlet="editTemplate; context: {item: item, index: i}"></ng-container>
    </ng-container>
  </div>
</div>

<ng-template #defaultTemplate let-item="item" let-index="index">
  {{item}}
</ng-template>

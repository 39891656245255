import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Book, GenerateBook} from '../data/book';
import {ApiEndpoint} from '../../environments/api-endpoint';
import {finalize} from 'rxjs/operators';
import {ProgressService} from './progress.service';

@Injectable({
  providedIn: 'root'
})
export class BookService {

  constructor(private httpClient: HttpClient, private progressService: ProgressService) {
  }

  getBook(bookId: number): Observable<Book> {
    const task = this.progressService.addTask('getBook');
    return this.httpClient.get<Book>(`${ApiEndpoint.getBookEndpoint}?book_id=${bookId}`).pipe(
      finalize(() => this.progressService.removeTask(task.id))
    );
  }

  getBooks(): Observable<Book[]> {
    const task = this.progressService.addTask('getBooks');
    return this.httpClient.get<Book[]>(ApiEndpoint.getBooksEndpoint).pipe(
      finalize(() => this.progressService.removeTask(task.id))
    );
  }

  generateBook(book: GenerateBook): Observable<Book> {
    const task = this.progressService.addTask('generateBook');
    return this.httpClient.post<Book>(ApiEndpoint.generateEndpoint, book).pipe(
      finalize(() => this.progressService.removeTask(task.id))
    );
  }

  saveBook(book: Book): Observable<Book> {
    const task = this.progressService.addTask('saveBook');
    return this.httpClient.post<Book>(ApiEndpoint.editBook, {book}).pipe(
      finalize(() => this.progressService.removeTask(task.id))
    );
  }
}

<div class="modal-backdrop fade show"></div>
<div class="modal fade show" tabindex="-1" role="dialog" data-backdrop="static"
     aria-modal="true" style="display: block;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <nav class="progress-header shadow-sm">
        <div class="title">{{title}}</div>
      </nav>
      <div class="progress-indicator" *ngIf="showProgress">
        <div class="row">
          <div class="col-12">
            <div class="progressbar-container shadow-sm">
              <div class="header">
                <span>Generating...</span>
                <!--<span>00:03.75</span>-->
              </div>
              <div class="progress">
                <progressbar [value]="100" [animate]="true" [striped]="true"></progressbar>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid bottom" *ngIf="showFooter">
        <div class="badge-container">
          <div class="badge container">
            <div class="badge-item" [style.backgroundImage]="'url(\'/assets/img/one_million.png\')'"></div>
            <div class="badge-item" [style.backgroundImage]="'url(\'/assets/img/satisfaction.png\')'"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import {environment} from './environment';

export class ApiEndpoint {
  static loginEndpoint = environment.serverEndpoint + '/login';
  static getAlbumsEndpoint = environment.serverEndpoint + '/api/get_albums';
  static getPhotosEndpoint = environment.serverEndpoint + '/api/get_photos';
  static generateEndpoint = environment.serverEndpoint + '/api/generate_book';
  static getBookEndpoint = environment.serverEndpoint + '/api/get_book';
  static getBooksEndpoint = environment.serverEndpoint + '/api/get_books';
  static getLongLivedToken = environment.serverEndpoint + '/api/facebook_login';
  static editBook = environment.serverEndpoint + '/api/edit_book';
  static getPricesEndpoint = environment.serverEndpoint + '/api/get_prices';
  static calculatePriceEndpoint = environment.serverEndpoint + '/api/calculate_price';
  static checkoutEndpoint = environment.serverEndpoint + '/api/checkout';
  static getRecommendedOrientationEndpoint = environment.serverEndpoint + '/api/recommended_orientation';
  static getOrderDetailsEndpoint = environment.serverEndpoint + '/api/get_order';
  static generatePdfEndpoint = environment.serverEndpoint + '/api/generate_pdf';
  static sendMarketingConsentEndpoint = environment.serverEndpoint + '/api/accept_marketing_tos';
  static shareDiscountEndpoint = environment.serverEndpoint + '/api/share_discount';
}

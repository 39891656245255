<div class="create-book-container">
  <header>
    <nav class="progress-header shadow-sm">
      <div class="container">
        <ul>
          <li [ngClass]="{'active' : activeIndex == 0}">1</li>
          <li [ngClass]="{'active' : activeIndex == 1}">2</li>
          <li [ngClass]="{'active' : activeIndex == 2}">3</li>
        </ul>
        <ng-container *ngIf="activeIndex == 0">
          <p class="title">Step 1: Import your photos</p>
          <p>Choose your preferred source below, <br>
            and we'll help you find your best photos <br>
            with some magic.</p>
        </ng-container>
        <ng-container *ngIf="activeIndex == 1">
          <p class="title">Step 2: Select your photos</p>
          <p>Choose the photos you'd like to use to create your perfect photo book.<br>You can use the photos we recommend, or make your own selection.</p>
        </ng-container>
        <ng-container *ngIf="activeIndex == 2">
          <p class="title">Step 3: Pick your format</p>
          <p>Choose your preferred book format below. <br>Based on your photos we recommend a <br>{{orientationList && orientationList[0] ? orientationList[0].name : ''}} format.
        </ng-container>
      </div>
    </nav>
    <div class="container-fluid bg-gray" *ngIf="activeIndex == 1">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-12 action-row-padding">
            <div class="row justify-content-center">
              <div class="col-lg-7 col-8 pl-0">
                <div class="form-group mb-0">
                  <select class="form-control" id="shortBy" [(ngModel)]="sortDirection">
                    <option [value]="'desc'">Sort by date (descending)</option>
                    <option [value]="'asc'">Sort by date (ascending)</option>
                  </select>
                  <fa-icon [icon]="faChevronDown"></fa-icon>
                </div>
              </div>
              <div class="col-lg-3 col-4 px-0">
                <div dropdown class="dropdown">
                  <button type="button" class="btn btn--outline btn--full-width text-capitalize px-1"
                          dropdownToggle data-toggle="dropdown" [style.height]="'38.5px'">
                    <fa-icon [icon]="faPlus"></fa-icon>
                    Add photos
                  </button>
                  <div *dropdownMenu class="dropdown-menu"
                       style="width: 100%; background: white; padding: 1em; border-radius: 0.3em;">
                    <button type="button" class="btn btn--facebook btn-block"
                            (click)="getFacebookAlbums(undefined, true)">
                      Facebook
                    </button>
                    <button type="button" class="btn btn--instagram btn-block"
                            (click)="getInstagramAlbums(undefined, true)">
                      Instagram
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-12 d-flex justify-content-center align-items-center action-row-padding">
            <ul class="tabs tabs--bottom-border">
              <li [ngClass]="{'active': selectedTab == 'smart'}">
                <a href="" (click)="$event.preventDefault(); changeTab('smart')">Smart selection</a>
              </li>
              <li [ngClass]="{'active': selectedTab == 'like'}" *ngIf="instagramPictures == false">
                <a href="" (click)="$event.preventDefault(); changeTab('like');">Most liked</a>
              </li>
              <li [ngClass]="{'active': selectedTab == 'all'}">
                <a href="" (click)="$event.preventDefault(); changeTab('all');">Select all</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
  <main [ngClass]="{'shadow-top': activeIndex == 1}" [style.paddingTop]="activeIndex !== 0 ? '1.5rem' : '0'">
    <ng-container *ngIf="activeIndex == 0">
      <div class="container-fluid bg-white">
        <div class="container top">
          <div class="row text-center d-flex justify-content-center align-items-center" [style.margin]="'1rem'">
            <button type="button" class="btn btn--facebook" [style.width]="'340px'" [style.height]="'40px'"
                    [style.margin]="'0.5em'"
                    (click)="getFacebookAlbums()">Facebook
            </button>
            <button type="button" class="btn btn--instagram" [style.width]="'340px'" [style.height]="'40px'"
                    [style.margin]="'0.5em'"
                    (click)="getInstagramAlbums()">Instagram
            </button>
          </div>
          <div class="row text-center">
            <div class="col-md">
              <div
                [style.marginTop]="'65px'"
                [style.fontSize]="'26px'"
              >
                Create your photo book in
              </div>
              <div
                [style.fontFamily]="'raleway-black'"
                [style.fontWeight]="'900'"
                [style.fontSize]="'36px'"
              >
                55 seconds!
              </div>
            </div>
          </div>
        </div>
      </div>
      <div [style.position]="'fixed'" [style.bottom]="'50px'" [style.width]="'100%'">
        <div class="badge-container">
          <div class="badge container">
            <div class="badge-item" [style.backgroundImage]="'url(\'/assets/img/one_million.png\')'"></div>
            <div class="badge-item" [style.backgroundImage]="'url(\'/assets/img/satisfaction.png\')'"></div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="activeIndex == 0 && showDialog">
      <div class="modal-backdrop fade show"></div>
      <div class="modal fade bd-example-modal-xl show" tabindex="-1" role="dialog" data-backdrop="static"
           aria-modal="true" style="display: block;" (click)="showDialog = false;">
        <div class="modal-dialog modal-l" role="document" (click)="$event.stopPropagation();">
          <div class="modal-content">
            <div class="modal-header" [style.margin]="'6px'" [style.minHeight]="'4.8125rem'">
              <h5 class="modal-title" [style.padding]="'29px 0'">Select albums to use</h5>
              <div class="button-container">
                <button type="button" (click)="dateRange = []" *ngIf="dateRange && dateRange.length > 0">
                  <fa-icon [icon]="faTrashAlt"></fa-icon>
                </button>
                <button
                  type="button"
                  (click)="datePicker.click()"
                  [style.display]="'flex'"
                  [style.alignItems]="'center'"
                  [style.justifyContent]="'center'"
                >
                  <fa-icon [icon]="faCalendarAlt"></fa-icon>
                </button>
                <span *ngIf="!dateRange || dateRange.length === 0">Specify <br>dates</span>
                <span *ngIf="dateRange && dateRange.length > 0">
                  {{dateRange[0] | date:'yyyy.MM.dd.'}}<br>{{dateRange[1] | date:'yyyy.MM.dd.'}}
                </span>
              </div>
            </div>
            <input type="text"
                   #datePicker
                   [(ngModel)]="dateRange"
                   [style.height]="0"
                   [style.padding]="0"
                   [style.border]="'none'"
                   class="form-control"
                   [bsConfig]="{ showClearButton: true, clearPosition: 'right' }"
                   [maxDate]="maxDate"
                   bsDaterangepicker>
            <ng-container *ngIf="(!albumResponse.createdAlbums || albumResponse.createdAlbums.length == 0) &&
              (!albumResponse.instagramAlbums || albumResponse.instagramAlbums.length == 0) &&
              (!albumResponse.facebookAlbums || albumResponse.facebookAlbums.length == 0)">
              <h5 class="text-center p-3">There is no album matching the selected date range</h5>
            </ng-container>
            <ng-container *ngIf="albumResponse.createdAlbums && albumResponse.createdAlbums.length > 0">
              <div class="modal-separator">
                <h6 class="modal-title">Our smart selections for you:</h6>
              </div>
              <div class="modal-body">
                <ul class="album-list">
                  <li class="album-list-item" *ngFor="let createdAlbum of albumResponse.createdAlbums"
                      [ngClass]="{'selected': selectedAlbums.indexOf(createdAlbum.id) > -1}"
                      (click)="toggleAlbum(createdAlbum)">
                    <div class="single-image"
                         [style.backgroundImage]="'url(\'' + createdAlbum.coverPhoto + '\')'"></div>
                    <div class="album-list-title">
                      <h5>{{createdAlbum.title}}</h5>
                      <span class="album-year" *ngIf="createdAlbum.year">({{createdAlbum.year}})</span>
                      <p>
                        <small>{{createdAlbum.photos + ' photo' + (createdAlbum.photos > 1 ? 's' : '')}}</small>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </ng-container>
            <ng-container *ngIf="albumResponse.instagramAlbums && albumResponse.instagramAlbums.length > 0">
              <div class="modal-separator">
                <h6 class="modal-title">All Instagram albums:</h6>
              </div>
              <div class="modal-body">
                <ul class="album-list">
                  <li class="album-list-item" *ngFor="let instagramAlbum of albumResponse.instagramAlbums"
                      [ngClass]="{'selected': selectedAlbums.indexOf(instagramAlbum.id) > -1}"
                      (click)="toggleAlbum(instagramAlbum)">
                    <div class="single-image"
                         [style.backgroundImage]="'url(\'' + instagramAlbum.coverPhoto + '\')'"></div>
                    <div class="album-list-title">
                      <h5>{{instagramAlbum.title}}</h5>
                      <p>
                        <small>{{instagramAlbum.photos + ' photo' + (instagramAlbum.photos > 1 ? 's' : '')}}</small>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </ng-container>
            <ng-container *ngIf="albumResponse.facebookAlbums && albumResponse.facebookAlbums.length > 0">
              <div class="modal-separator">
                <h6 class="modal-title">All Facebook albums:</h6>
              </div>
              <div class="modal-body">
                <ul class="album-list">
                  <li class="album-list-item" *ngFor="let facebookAlbum of albumResponse.facebookAlbums"
                      [ngClass]="{'selected': selectedAlbums.indexOf(facebookAlbum.id) > -1}"
                      (click)="toggleAlbum(facebookAlbum)">
                    <div class="single-image"
                         [style.backgroundImage]="'url(\'' + facebookAlbum.coverPhoto + '\')'"></div>
                    <div class="album-list-title">
                      <h5>{{facebookAlbum.title}}</h5>
                      <p>
                        <small>{{facebookAlbum.photos + ' photo' + (facebookAlbum.photos > 1 ? 's' : '')}}</small>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="container" *ngIf="activeIndex == 1">
      <div class="row">
        <div class="col-md">
          <ul class="image-list">
            <li [ngClass]="{'selected': isImageSelected(image) || selectedTab == 'all'}" *ngFor="let image of imageList"
                (click)="toggleImage(image)">
              <div class="single-image" [style.backgroundImage]="'url(\'' + image.url + '\')'"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container" *ngIf="activeIndex == 2">
      <div class="row">
        <ul class="album-list format-picker" [style.marginBottom]="'50px'">
          <li class="album-list-item" [ngClass]="{'selected': selectedFormat == orientation.orientation}"
              (click)="selectedFormat = orientation.orientation"
              *ngFor="let orientation of orientationList; let index = index">
            <div class="label" *ngIf="index == 0">Recommended</div>
            <div class="single-image" [style.backgroundImage]="'url(\'' + orientation.url +'\')'"></div>
            <div class="album-list-title">
              <h5>{{orientation.name}}</h5>
              <p>
                <small>Starting at ${{orientation.price | number:'1.0-2'}}</small>
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <ng-container *ngIf="activeIndex == 2 && showInputDialog">
      <app-input-dialog
        title="Give your photo book the perfect title!"
        positiveButtonText="Show me my book!"
        placeholder="My photo book"
        [maxLength]="50"
        (closeDialog)="$event ? createBook($event) : showInputDialog = false">
      </app-input-dialog>
    </ng-container>
    <ng-container *ngIf="instagramErrorDialog">
      <app-ok-dialog
        title="Error in instagram login"
        positiveButtonText="Close"
        (closeDialog)="instagramErrorDialog = false; router.navigate(['/create-book']).then()">
      </app-ok-dialog>
    </ng-container>
    <ng-container *ngIf="showSelectedPhotoLossWarningDialog">
      <app-ok-dialog
        body="Your settings will be lost if you navigate away from this page.<br/>Are you sure that you would like to continue?"
        positiveButtonText="OK"
        negativeButtonText="Cancel"
        (closeDialog)="warningDialogClosed($event)">
      </app-ok-dialog>
    </ng-container>
  </main>
  <footer class="footer bg-white" [ngClass]="{'footer--modal-footer': showDialog && activeIndex == 0}">
    <div class="container text-center" *ngIf="activeIndex == 0 && !showDialog">
      <div class="col-md">
        <a href="" class="text-uppercase" [routerLink]="'/profile'">Back</a>
      </div>
    </div>
    <div class="container" *ngIf="activeIndex == 0 && showDialog">
      <div class="row">
        <div class="col d-flex align-items-center justify-content-between">
          <p class="small" [style.visibility]="getPictureCountInSelectedAlbum() < 24 ? 'visible' : 'hidden'">
            Minimum 24 photos are <br>
            required to create a book
          </p>
          <div>
            <a href="" class="text-uppercase mr-4" [routerLink]="'/profile'">Back</a>
            <button type="button" class="btn btn--gradient px-5" [disabled]="getPictureCountInSelectedAlbum() < 24"
                    (click)="downloadImages()">Next
            </button>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="activeIndex == 1">
      <div class="info" *ngIf="selectedImages.length < 24">
        <img width="40px" src="/assets/img/min_24_img.svg" class="pr-2">
        <div class="d-flex align-items-center">
          <strong>You need minimum 24 photos to create a book.</strong>
        </div>
      </div>
      <div class="info" *ngIf="pages > 24">
        <img src="/assets/img/icon-smiley-wink.png" class="pr-2">
        <strong>You're now creating a book with more than 24 pages. Your book will be amazing, but will cost + $0.5 /
          page.</strong>
      </div>
      <div class="container">
        <div class="row">
          <div class="col d-flex align-items-center justify-content-between">
            <p class="small mobile">
              Total photos: <strong>{{imageList.length}}</strong><br>
              Selected photos <strong>{{selectedTab == 'all' ? imageList.length : selectedImages.length}}</strong><br>
              Pages: <strong>{{pages}}</strong>
            </p>
            <p class="desktop" [style.fontSize]="'14px'" [style.margin]="'0'">
              Total photos: <strong>{{imageList.length}}</strong> |
              Selected photos <strong>{{selectedTab == 'all' ? imageList.length : selectedImages.length}}</strong> |
              Pages: <strong>{{pages}}</strong>
            </p>
            <div>
              <a href="" class="text-uppercase mr-4" (click)="$event.preventDefault(); activeIndex = 0">Back</a>
              <button type="button" class="btn btn--gradient px-5" (click)="activeIndex = 2"
                      [disabled]="selectedImages.length < 24 && selectedTab != 'all'">Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="container text-center" *ngIf="activeIndex == 2">
      <div class="row">
        <div class="col-6">
          <a href="" class="text-uppercase" (click)="$event.preventDefault(); activeIndex = 1">Back</a>
        </div>
        <div class="col-6">
          <button type="button" class="btn btn--gradient" (click)="showInputDialog = true;">Create my book</button>
        </div>
      </div>
    </div>
  </footer>
</div>
<ng-container *ngIf="showMagicProgress">
  <app-full-screen-progress
    title="We are doing some magic with your photos..."
    [showFooter]="true">
  </app-full-screen-progress>
</ng-container>
<ng-container *ngIf="showGenerateProgress">
  <app-full-screen-progress
    title="We are building you a lovely book..."
    [showFooter]="true"
    [showProgress]="true">
  </app-full-screen-progress>
</ng-container>

<header class="bg-white shadow-sm">
  <nav class="navbar navbar-expand">
    <div class="container">
      <div class="app-logo" [style.backgroundImage]="'url(\'/assets/img/pic_roc_logo.svg\')'"></div>
      <p class="title">Hi, {{ CredentialsService.facebookSocialUser ? CredentialsService.facebookSocialUser.firstName : 'there'}}</p>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <div class="profile-picture" [style.backgroundImage]="CredentialsService.profilePictureUrl"></div>
        <ul class="navbar-nav">
          <li class="nav-item dropdown" dropdown>
            <a id="navbarDropdown" class="nav-link dropdown-toggle" dropdownToggle href="" role="button"
               (click)="$event.preventDefault()"
               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <fa-icon [icon]="faChevronDown"></fa-icon>
              <span class="caret"></span>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown" *dropdownMenu>
              <a class="dropdown-item" href="" [routerLink]="'/profile'">Home</a>
              <a class="dropdown-item" href="">Orders</a>
              <a class="dropdown-item" href="" (click)="$event.preventDefault(); credentialsService.signOut().subscribe()">Logout</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div class="container">
    <div class="row" [style.justifyContent]="'center'">
      <ul class="tabs">
        <li [ngClass]="{'active' : activePage == 'books' }">
          <a href="#" [routerLink]="['/profile/books']">Your books</a>
        </li>
        <li [ngClass]="{'active' : activePage == 'photos', 'disabled': !bookList || bookList.length == 0 }">
          <a *ngIf="bookList?.length > 0" href="#" [routerLink]="['/profile/photos']">Your photos</a>
          <a *ngIf="!bookList || bookList.length == 0" href="#" (click)="$event.preventDefault(); showNoPhotoDialog = true;">Your photos</a>
        </li>
      </ul>
    </div>
  </div>
</header>
<main [style.paddingTop]="activePage == 'photos' || bookList?.length > 0 ? '1.5em' : '0'">
  <ng-container *ngIf="activePage === 'books'">
    <div class="container-fluid" *ngIf="!bookList || bookList.length == 0">
      <div class="container top">
        <div class="row text-center">
          <div class="col-md mobile">
            <h1><span class="small">You didn't create any photobooks yet.</span> <br>
              Get started!</h1>
            <img src="/assets/img/icon-get-started.png" alt="" class="bounce">
          </div>
          <div class="col-md desktop" [style.marginTop]="'76px'">
            <div [style.backgroundImage]="'url(\'/assets/img/icon-book-2.png\')'" [style.backgroundRepeat]="'no-repeat'"
                 [style.width]="'100%'" [style.height]="'132px'" [style.backgroundSize]="'contain'"
                 [style.backgroundPosition]="'center'"></div>
            <div [style.fontSize]="'1.62rem'" [style.marginTop]="'27px'">You didn't create any photobooks yet.</div>
            <h1>Get started!</h1>
            <button type="button" class="btn btn--gradient btn--full-width" (click)="createBook()"
              [style.maxWidth]="'340px'">
              Create your first photo book!
            </button>
          </div>
        </div>

      </div>
    </div>
    <div class="container" *ngIf="bookList?.length > 0" [style.paddingBottom]="'calc(50px + 40px + 4rem)'">
      <div class="row">
        <div class="col-md-6 col-lg-4 desktop">
          <div class="card mb-4 shadow-sm">
            <div class="card-body" [style.height]="'276px'">
              <div class="d-flex flex-column justify-content-between align-items-center"
                   [style.height]="'100%'">
                <div class="text-group" [style.height]="'100%'">
                  <div [style.fontSize]="'26px'" [style.marginBottom]="'1rem'" [style.lineHeight]="'normal'">
                    Create your next photo book
                  </div>
                  <div [style.height]="'4.7em'" [style.overflow]="'hidden'" [style.fontSize]="'0.95rem'">
                    Use your Facebook or Instagram photos to create a gorgeous looking, premium quality photo book!
                  </div>
                </div>
                <hr [style.width]="'calc(100% + 2.5rem)'" [style.marginLeft]="'-1.25rem'" [style.marginBottom]="'1.25rem'"/>
                <button type="button" class="btn btn--gradient btn--full-width"
                        (click)="createBook()">
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4" *ngFor="let book of bookList" [style.cursor]="'pointer'"
             [routerLink]="['/book/' + book.id + '/book']">
          <div class="card mb-4 shadow-sm">
            <div class="card-image-container">
              <div class="card-cover" [style.backgroundImage]="'url(\'' + book.cover.photoList[0].url + '\')'">
              </div>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between align-items-center">
                <div class="text-group">
                  <h5 class="card-title">{{book.title}}</h5>
                  <span
                    class="card-count">{{getBookPhotoCount(book)}}</span>
                  <p class="card-text">
                    <small>{{ book.modifiedDate * 1000 | timeAgo }}</small>
                  </p>
                </div>
                <div class="btn-group">
                  <button type="button" class="btn btn-sm btn-outline-dark">
                    <fa-icon [icon]="faPencilAlt"></fa-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="activePage === 'photos'">
    <div class="container top mobile" id="page--have-photos"
         [style.paddingBottom]="'calc(50px + 40px + 4rem)'">
      <ng-container *ngFor="let album of albumList">
        <div class="row" (click)="createBookFromAlbum(album)">
          <div class="col-md">
            <ul class="image-list">
              <ng-container *ngFor="let image of album.photoList?.slice(0, 5)">
                <li [attr.data-after-content]="'\+' + (album.photos - 4)" [ngClass]="{'clear': album.photos < 6}">
                  <div class="single-image" [style.backgroundImage]="'url(\'' + image.url + '\')'"></div>
                </li>
              </ng-container>
            </ul>
            <div class="list-title">
              <h5>{{album.title}}</h5>
              <span> ({{album.source}})</span>
              <p>
                <small>{{album.photos + ' photo' + (album.photos > 1 ? 's' : '')}}</small>
              </p>
            </div>
          </div>
        </div>
        <hr>
      </ng-container>
    </div>
    <div class="container desktop" *ngIf="albumList?.length > 0" [style.paddingBottom]="'calc(50px + 40px + 4rem)'"
         id="page--have-photos-desktop">
      <div class="row">
        <div class="col-md-6 col-lg-4 desktop">
          <div class="card mb-4 shadow-sm">
            <div class="card-body marketing">
              <div class="d-flex flex-column justify-content-between align-items-center"
                   [style.height]="'100%'">
                <div class="text-group" [style.height]="'100%'">
                  <div [style.fontSize]="'26px'" [style.marginBottom]="'1rem'" [style.lineHeight]="'normal'">
                    Create your next photo book
                  </div>
                  <div [style.height]="'4.7em'" [style.overflow]="'hidden'" [style.fontSize]="'0.95rem'">
                    Use your Facebook or Instagram photos to create a gorgeous looking, premium quality photo book!
                  </div>
                </div>
                <hr [style.width]="'calc(100% + 2.5rem)'" [style.marginLeft]="'-1.25rem'" [style.marginBottom]="'1.25rem'"
                    [style.paddingTop]="'0.55rem'"/>
                <button type="button" class="btn btn--gradient btn--full-width"
                        (click)="createBook()">
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4" *ngFor="let album of albumList" [style.cursor]="'pointer'">
          <div class="card mb-4 shadow-sm" (click)="createBookFromAlbum(album)">
            <div class="card-image-container top" [style.backgroundImage]="'none'">
              <ul class="image-list">
                <ng-container *ngFor="let image of album.photoList?.slice(0, 5)">
                  <li [attr.data-after-content]="'\+' + (album.photos - 4)" [ngClass]="{'clear': album.photos < 6}">
                    <div class="single-image" [style.backgroundImage]="'url(\'' + image.url + '\')'"></div>
                  </li>
                </ng-container>
              </ul>
            </div>
            <div class="card-body" [style.paddingTop]="'2.1rem'">
              <hr [style.width]="'calc(100% + 2.5rem)'" [style.marginLeft]="'-1.25rem'" [style.marginBottom]="'1.25rem'"
                  [style.marginTop]="'0'"/>
              <div class="d-flex justify-content-between align-items-center">
                <div class="text-group">
                  <h5 class="card-title">{{album.title + ' '}}</h5>
                  <span
                    class="card-count">({{album.source | titlecase}})</span>
                  <p class="card-text">
                    <small>{{album.photos + ' photo' + (album.photos > 1 ? 's' : '')}}</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="showDialog">
    <app-ok-dialog
      title="Wait! There is a save for your initial book!"
      body="Hold on, you have a photo book previously created with PicRoc.<br>Would you like to continue editing it?"
      positiveButtonText="YES, TAKE ME TO THE DRAFT PHOTO BOOK"
      negativeButtonText="NO, I’D LIKE TO START A NEW BOOK"
      (closeDialog)="(!$event ? CreateBookComponent.eraseSave() : undefined); router.navigate(['/create-book']).then()">
    </app-ok-dialog>
  </ng-container>
  <ng-container *ngIf="showNoPhotoDialog">
    <app-ok-dialog
      title="Attention"
      body="You don’t have any photos uploaded to PicRoc yet."
      positiveButtonText="OK"
      (closeDialog)="showNoPhotoDialog = false;">
    </app-ok-dialog>
  </ng-container>
</main>
<footer class="footer bg-white">
  <div class="badge-container">
    <div class="badge container">
      <div class="badge-item" [style.backgroundImage]="'url(\'/assets/img/one_million.png\')'"></div>
      <div class="badge-item" [style.backgroundImage]="'url(\'/assets/img/satisfaction.png\')'"></div>
    </div>
  </div>
  <div class="container text-center">
    <div class="col-md">
      <button type="button" class="btn btn--gradient btn--full-width" (click)="createBook()" [style.maxWidth]="'340px'">
        Create your {{ bookList?.length > 0 ? 'next' : 'first' }} photo book!
      </button>
    </div>
  </div>
</footer>

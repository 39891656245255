import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OrderService} from '../../services/order.service';
import {OrderDetails} from '../../data/order';
import {CredentialsService} from '../../services/credentials.service';
import {CreateBookComponent} from '../create-book/create-book.component';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {
  faChevronDown = faChevronDown;
  CreateBookComponent: any = CreateBookComponent;
  CredentialsService: any = CredentialsService;
  orderDetails: OrderDetails;
  showDialog = false;
  error = false;

  constructor(private activatedRoute: ActivatedRoute, private orderService: OrderService, public credentialsService: CredentialsService,
              public router: Router) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.orderService.getOrderDetails(params.orderUuid).subscribe(
        response => {
          this.orderDetails = response;
        },
        () => {
          this.error = true;
        });
    });
  }

  createBook(): void {
    if (localStorage.getItem('selectedAlbums')) {
      this.showDialog = true;
    } else {
      CreateBookComponent.eraseSave();
      this.router.navigate(['/create-book']).then();
    }
  }
}

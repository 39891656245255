import {ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {faImage, faTrashAlt} from '@fortawesome/free-regular-svg-icons';
import {faExchangeAlt, faFont, faMinus, faPencilAlt, faPlus, faUndo} from '@fortawesome/free-solid-svg-icons';
import {Book, Photo, PhotobookImage, PhotoData} from '../../data/book';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss']
})
export class ImageEditorComponent implements OnInit {
  faMinus: any = faMinus;
  faPlus: any = faPlus;
  faTrashAlt: any = faTrashAlt;
  fasImage: any = faImage;
  faImage: any = faImage;
  faExchangeAlt: any = faExchangeAlt;
  faUndo: any = faUndo;
  faFont: any = faFont;
  faPencilAlt: any = faPencilAlt;
  @Input() book: Book;
  @Input() pageIndex: number;
  @Output() deletePhoto = new EventEmitter<{ pageIndex: number, imageIndex: number }>();
  @Output() useAsCover = new EventEmitter<string>();
  @Output() useAsBackCover = new EventEmitter<string>();
  @Output() save: EventEmitter<{photoData: PhotoData, cover: boolean, backcover: boolean}> =
    new EventEmitter<{photoData: PhotoData, cover: boolean, backcover: boolean}>();
  @Output() photoChange: EventEmitter<{ pageIndex: number, imageIndex: number, photo: PhotobookImage, cover: boolean, backcover: boolean}>
    = new EventEmitter<{ pageIndex: number, imageIndex: number, photo: PhotobookImage, cover: boolean, backcover: boolean}>();
  @Output() closeDialog: EventEmitter<any> = new EventEmitter<any>();
  changePhotoDialog = false;
  @ViewChild('textContainer') textContainer: ElementRef;
  showVerticalGuide = false;
  showHorizontalGuide = false;
  get cover(): boolean {
    return this.data.cover;
  }
  get backcover(): boolean {
    return this.data.backcover;
  }
  showInputDialog = false;
  private _fontEdit = false;
  get fontEdit(): boolean {
    return this._fontEdit;
  }

  set fontEdit(value: boolean) {
    this.oldTouchX = undefined;
    this.oldTouchY = undefined;
    if (this.newImageData.fontText?.length > 0) {
      this._fontEdit = value;
    } else {
      this.editText();
    }
    this.updateGuidelinesVisibility();
  }

  private _data: { ratio: number; image: PhotobookImage; cover: boolean; backcover: boolean; imageIndex: number; };
  @Input() set data(value: { ratio: number; image: PhotobookImage; cover: boolean; backcover: boolean; imageIndex: number; }) {
    const previousData = this._data;
    this._data = value;
    if (!value) {
      this.resetImageData();
    } else {
      this.newImageData = {...value.image.frontendData};
    }
    if (!previousData && (!value.image.url || value.image.url.length === 0)) {
      // If image is empty then open change
      this.showChangePhotoDialog();
    }
  }

  get data(): { ratio: number; image: PhotobookImage; cover: boolean; backcover: boolean; imageIndex: number; } {
    return this._data;
  }

  imageDragging = false;
  newImageData: PhotoData =
    {backgroundPositionX: undefined, backgroundPositionY: undefined, size: undefined, rotate: undefined, fontSize: undefined,
      fontPositionX: undefined, fontPositionY: undefined, fontColor: undefined, fontText: ''};
  oldTouchX: number;
  oldTouchY: number;
  imageHolder: HTMLElement;
  newPhoto: Photo;

  constructor(private changeDetectorRef: ChangeDetectorRef, private router: Router, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.changeDetectorRef.markForCheck();
  }

  private updateGuidelinesVisibility(): void {
    if (this.fontEdit && this.textContainer) {
      const verticalDiff = Math.abs(Math.round(this.textContainer.nativeElement.parentNode.clientWidth / 2) -
        Math.round(this.textContainer.nativeElement.offsetLeft + this.textContainer.nativeElement.clientWidth / 2));
      this.showVerticalGuide = verticalDiff <= 2;
      const horizontalDiff = Math.abs(Math.round(this.textContainer.nativeElement.parentNode.clientHeight / 2) -
        Math.round(this.textContainer.nativeElement.offsetTop + this.textContainer.nativeElement.clientHeight / 2));
      this.showHorizontalGuide = horizontalDiff <= 2;
    } else {
      this.showVerticalGuide = false;
      this.showHorizontalGuide = false;
    }
  }

  calculatePadding(): string {
    let padding;
    if (this.data.ratio < 1) {
      padding = '0 ' + Math.abs((100 - this.data.ratio * 100) / 2) + '%';
    } else {
      const percent = (100 - (100 / this.data.ratio));
      padding = 'calc(' + percent + '% / 2) 0';
    }
    return padding;
  }

  mouseMove(event: MouseEvent): void {
    if (this.imageDragging) {
      const pxToPercentRatio = this.imageHolder.offsetWidth / ((window.innerWidth + window.innerHeight) / 2);
      if (this.fontEdit) {
        this.newImageData = {
          backgroundPositionX: this.newImageData.backgroundPositionX,
          backgroundPositionY: this.newImageData.backgroundPositionY,
          size: this.newImageData.size,
          rotate: this.newImageData.rotate,
          fontSize: this.newImageData.fontSize,
          fontPositionX: (isNaN(this.newImageData.fontPositionX) ? 0 : this.newImageData.fontPositionX) +
            event.movementX * pxToPercentRatio,
          fontPositionY: (isNaN(this.newImageData.fontPositionY) ? 0 : this.newImageData.fontPositionY) +
            event.movementY * pxToPercentRatio,
          fontColor: this.newImageData.fontColor,
          fontText: this.newImageData.fontText
        };
        this.updateGuidelinesVisibility();
      } else {
        this.newImageData = {
          backgroundPositionX: (isNaN(this.newImageData.backgroundPositionX) ? 0 : this.newImageData.backgroundPositionX) +
            (Math.abs(this.newImageData.rotate) === 90 || Math.abs(this.newImageData.rotate) === 270 ?
              -1 * event.movementY : event.movementX) * pxToPercentRatio,
          backgroundPositionY: (isNaN(this.newImageData.backgroundPositionY) ? 0 : this.newImageData.backgroundPositionY) +
            (Math.abs(this.newImageData.rotate) === 90 || Math.abs(this.newImageData.rotate) === 270 ?
              event.movementX : event.movementY) * pxToPercentRatio,
          size: this.newImageData.size,
          rotate: this.newImageData.rotate,
          fontSize: this.newImageData.fontSize,
          fontPositionX: this.newImageData.fontPositionX,
          fontPositionY: this.newImageData.fontPositionY,
          fontColor: this.newImageData.fontColor,
          fontText: this.newImageData.fontText
        };
      }
    }
  }

  touchMove(event: TouchEvent): void {
    if (this.imageDragging) {
      if (this.oldTouchX !== undefined || this.oldTouchY !== undefined) {
        const pxToPercentRatio = this.imageHolder.offsetWidth / ((window.innerWidth + window.innerHeight) / 2);
        const deltaX = (this.oldTouchX - event.changedTouches[0].clientX) * pxToPercentRatio;
        const deltaY = (this.oldTouchY - event.changedTouches[0].clientY) * pxToPercentRatio;
        if (this.fontEdit) {
          this.newImageData = {
            backgroundPositionX: this.newImageData.backgroundPositionX,
            backgroundPositionY: this.newImageData.backgroundPositionY,
            size: this.newImageData.size,
            rotate: this.newImageData.rotate,
            fontSize: this.newImageData.fontSize,
            fontPositionX: (isNaN(this.newImageData.fontPositionX) ? 0 : this.newImageData.fontPositionX) - deltaX * pxToPercentRatio,
            fontPositionY: (isNaN(this.newImageData.fontPositionY) ? 0 : this.newImageData.fontPositionY) - deltaY * pxToPercentRatio,
            fontColor: this.newImageData.fontColor,
            fontText: this.newImageData.fontText
          };
        } else {
          this.newImageData = {
            backgroundPositionX: (isNaN(this.newImageData.backgroundPositionX) ? 0 : this.newImageData.backgroundPositionX) -
              (Math.abs(this.newImageData.rotate) === 90 || Math.abs(this.newImageData.rotate) === 270 ? -1 * deltaY : deltaX)
              * pxToPercentRatio,
            backgroundPositionY: (isNaN(this.newImageData.backgroundPositionY) ? 0 : this.newImageData.backgroundPositionY) -
              (Math.abs(this.newImageData.rotate) === 90 || Math.abs(this.newImageData.rotate) === 270 ? deltaX : deltaY)
              * pxToPercentRatio,
            size: this.newImageData.size,
            rotate: this.newImageData.rotate,
            fontSize: this.newImageData.fontSize,
            fontPositionX: this.newImageData.fontPositionX,
            fontPositionY: this.newImageData.fontPositionY,
            fontColor: this.newImageData.fontColor,
            fontText: this.newImageData.fontText
          };
        }
      }
      this.oldTouchX = event.changedTouches[0].clientX;
      this.oldTouchY = event.changedTouches[0].clientY;
    }
  }

  resetImageData(): void {
    this.newImageData = {backgroundPositionX: undefined, backgroundPositionY: undefined, size: undefined, rotate: undefined,
      fontSize: undefined, fontPositionX: undefined, fontPositionY: undefined, fontColor: undefined, fontText: ''};
  }

  getImageProperty(property: string, imageHolder?: HTMLElement): string | undefined {
    if (imageHolder) {
      this.imageHolder = imageHolder;
    }
    if (property.startsWith('backgroundPosition')) {
      let value: number;
      if (this.newImageData[property] !== undefined) {
        value = this.newImageData[property];
      } else {
        value = this.data.image.frontendData[property];
      }
      let containerSize: number;
      if (property.endsWith('X')) {
        containerSize = this.imageHolder.offsetWidth;
      } else {
        containerSize = this.imageHolder.offsetHeight;
      }
      return `${property.endsWith('X') ? 'translateX' : 'translateY'}(${containerSize * (value / 100)}px)`;
    } else if (property === 'rotate') {
      return `rotate(${this.newImageData.rotate}deg)`;
    } else if (property === 'fontSize') {
      let value: number;
      if (this.newImageData[property] !== undefined) {
        value = this.newImageData[property];
      } else {
        value = this.data.image.frontendData[property];
      }
      const containerSize = this.imageHolder.offsetHeight;
      return `${containerSize * (value / 100)}px`;
    } else if (property.startsWith('fontPosition')) {
      let value: number;
      if (this.newImageData[property] !== undefined) {
        value = this.newImageData[property];
      } else {
        value = this.data.image.frontendData[property];
      }
      let containerSize: number;
      if (property.endsWith('X')) {
        containerSize = this.imageHolder.offsetWidth;
      } else {
        containerSize = this.imageHolder.offsetHeight;
      }
      return `${containerSize * (value / 100)}px`;
    } else {
      if (this.newImageData[property] !== undefined) {
        return this.newImageData[property];
      } else {
        return this.data.image.frontendData[property];
      }
    }
  }

  editText(): void {
    this.showInputDialog = true;
  }

  closeInputDialog(input?: string): void {
    if (input && input.length > 0) {
      if (!this.newImageData.fontText || this.newImageData.fontText.trim().length === 0) {
        setTimeout(() => {
          this.newImageData.fontPositionX = (Math.round(this.textContainer.nativeElement.parentNode.clientWidth / 2) -
            Math.round(this.textContainer.nativeElement.clientWidth / 2)) / this.textContainer.nativeElement.parentNode.clientWidth * 100;
          this.newImageData.fontPositionY = (Math.round(this.textContainer.nativeElement.parentNode.clientWidth * 2 / 3) -
            Math.round(this.textContainer.nativeElement.clientHeight / 2)) / this.textContainer.nativeElement.parentNode.clientHeight * 100;
          setTimeout(() => {
            this.updateGuidelinesVisibility();
          }, 50);
        }, 100);
      }
      this._fontEdit = true;
      this.newImageData.fontText = input;
    }
    this.showInputDialog = false;
  }

  saveLayout(): void {
    const result = JSON.parse(JSON.stringify(this.newImageData));
    Object.keys(result).forEach(key => {
      if (result[key] === undefined) {
        result[key] = this.data.image.frontendData[key];
      }
    });
    if (this.newPhoto) {
      this.photoChange.emit({
        pageIndex: this.pageIndex,
        imageIndex: this.data.imageIndex,
        photo: {
          frontendData: result,
          photoNumber: this.data.image.photoNumber,
          id: this.data.image.id,
          url: this.data.image.url
        },
        cover: this.cover,
        backcover: this.backcover
      });
    } else {
      this.save.emit({photoData: result, cover: this.cover, backcover: this.backcover});
    }
  }

  photoChanged(event: Photo): void {
    this.newPhoto = event;
    this.resetImageData();
    this.data = {
      ...JSON.parse(JSON.stringify(this.data)),
      ...{
        image: {
          id: event.id,
          photoNumber: this.data.image.photoNumber,
          url: event.url,
          frontendData: {
            fontText: '',
            fontColor: '#ffffff',
            fontSize: 100,
            fontPositionX: 0,
            fontPositionY: 0,
            backgroundPositionX: 0,
            backgroundPositionY: 0,
            rotate: 0,
            size: 100,
          }
        }
      }
    };
    this.changePhotoDialog = false;
  }

  showChangePhotoDialog(): void {
    this.router.navigate([],
      {
        relativeTo: this.activatedRoute,
        queryParams: {
          showEditPage: this.data.imageIndex
        },
        queryParamsHandling: 'merge'
      }).then();
    this.changePhotoDialog = true;
  }
}

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CredentialsService} from '../../services/credentials.service';
import {Book} from '../../data/book';
import {OrderService} from '../../services/order.service';
import {forkJoin, of, Subscription} from 'rxjs';
import {faChevronDown, faMinus, faPlus, faShieldAlt} from '@fortawesome/free-solid-svg-icons';
import {BookService} from '../../services/book.service';
import {CalculatePriceResponse, ConfigureData} from '../../data/order';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {faCcAmex, faCcDiscover, faCcMastercard, faCcVisa} from '@fortawesome/free-brands-svg-icons';
import {catchError, debounceTime, distinctUntilChanged, filter, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.scss']
})
export class BasketComponent implements OnInit, OnDestroy {
  isNaN: any = isNaN;
  Number: any = Number;
  faChevronDown = faChevronDown;
  faPlus: any = faPlus;
  faMinus: any = faMinus;
  faCcVisa: any = faCcVisa;
  faCcMastercard: any = faCcMastercard;
  faCcAmex: any = faCcAmex;
  faCcDiscover: any = faCcDiscover;
  faShieldAlt: any = faShieldAlt;
  CredentialsService: any = CredentialsService;
  book: Book;
  configureData: ConfigureData;
  subscription: Subscription;
  formGroup: FormGroup = this.formBuilder.group({
    numberOfLargeOrder: [0, Validators.pattern(/^(0|[1-9][0-9]*)$/)],
    numberOfSmallOrder: [0, Validators.pattern(/^(0|[1-9][0-9]*)$/)],
    couponCode: null
  });
  priceOfSmallBook = 0;
  priceOfLargeBook = 0;
  finalPrice: CalculatePriceResponse;
  @ViewChild('couponCode') couponCode: any;

  constructor(public credentialsService: CredentialsService, private orderService: OrderService, private formBuilder: FormBuilder,
              private bookService: BookService, private activatedRoute: ActivatedRoute, private router: Router) {
  }

  ngOnInit(): void {
    this.formGroup.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      filter(() => this.formGroup.valid),
      switchMap(() => {
          const requestBook = {...this.book};
          requestBook.orderInfo.largeNumber = this.formGroup.controls.numberOfLargeOrder.value;
          requestBook.orderInfo.smallNumber = this.formGroup.controls.numberOfSmallOrder.value;
          requestBook.orderInfo.couponCode = this.formGroup.controls.couponCode.value;
          return this.orderService.calculatePrice(requestBook).pipe(
            catchError(() => {
              // TODO: show that coupon code not valid
              this.couponCode.nativeElement.value = '';
              this.formGroup.controls.couponCode.setValue(null);
              return of(this.finalPrice);
            })
          );
        }
      )).subscribe((priceResponse) => {
      this.finalPrice = priceResponse;
    });
    this.activatedRoute.params.subscribe(params => {
      this.subscription?.unsubscribe();
      this.subscription = forkJoin([
        this.orderService.getConfigureData(),
        this.bookService.getBook(params.bookId)
      ]).subscribe((response) => {
        if (response[1].status === 'editing' || response[1].status === undefined) {
          this.router.navigate(['/book/' + response[1].id + '/book'], {replaceUrl: true}).then();
        } else if (response[1].status === 'checkout') {
          this.router.navigate(['/checkout/' + response[1].id], {replaceUrl: true}).then();
        } else if (response[1].status === 'configured') {
          this.book = response[1];
          this.formGroup.controls.numberOfLargeOrder.setValue(this.book.orderInfo.largeNumber);
          this.formGroup.controls.numberOfSmallOrder.setValue(this.book.orderInfo.smallNumber);
          if (response[0].bookOrderData.small) {
            this.priceOfSmallBook = response[0].bookOrderData.small[this.book.orientation].basePrice + (this.book.pages.length - 24) *
              response[0].bookOrderData.small[this.book.orientation].additionalPagePrice;
          }
          if (response[0].bookOrderData.large) {
            this.priceOfLargeBook = response[0].bookOrderData.large[this.book.orientation].basePrice + (this.book.pages.length - 24) *
              response[0].bookOrderData.large[this.book.orientation].additionalPagePrice;
          }
          this.configureData = response[0];
        }
      });
    });
  }

  getBookData(): string {
    const terms: string[] = [];
    terms.push(`${this.book.pages.length} pages`);
    terms.push(this.book.orderInfo.featureList.map(feature =>
      this.configureData.bookOrderData.featureList.find(item => item.id === feature).name).join(', '));
    return terms.join(', ');
  }

  increaseNumberOfLargeOrder(): void {
    this.formGroup.controls.numberOfLargeOrder.setValue(this.formGroup.controls.numberOfLargeOrder.value + 1);
  }

  decreaseNumberOfLargeOrder(): void {
    this.formGroup.controls.numberOfLargeOrder.setValue(this.formGroup.controls.numberOfLargeOrder.value - 1);
  }

  increaseNumberOfSmallOrder(): void {
    this.formGroup.controls.numberOfSmallOrder.setValue(this.formGroup.controls.numberOfSmallOrder.value + 1);
  }

  decreaseNumberOfSmallOrder(): void {
    this.formGroup.controls.numberOfSmallOrder.setValue(this.formGroup.controls.numberOfSmallOrder.value - 1);
  }

  goToCheckout(): void {
    const request = {...this.book};
    request.status = 'checkout';
    request.orderInfo.largeNumber = this.formGroup.controls.numberOfLargeOrder.value;
    request.orderInfo.smallNumber = this.formGroup.controls.numberOfSmallOrder.value;
    request.orderInfo.couponCode = this.formGroup.controls.couponCode.value;
    this.bookService.saveBook(request).subscribe((response) => {
      this.router.navigate(['/checkout/' + response.id]).then();
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  goBackToEdit(): void {
    const request = {...this.book};
    request.status = 'editing';
    delete request.orderInfo;
    this.bookService.saveBook(request).subscribe((response) => {
      this.router.navigate(['/book/' + response.id + '/book'], {replaceUrl: true}).then();
    });
  }
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {FacebookLoginProvider, SocialAuthService, SocialUser} from 'angularx-social-login';
import {fromPromise} from 'rxjs/internal-compatibility';
import {Observable} from 'rxjs';
import {finalize, map, switchMap, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {ApiEndpoint} from '../../environments/api-endpoint';
import {Router} from '@angular/router';
import {ProgressService} from './progress.service';

@Injectable({
  providedIn: 'root'
})
export class CredentialsService {
  public user: SocialUser;
  static get profilePictureUrl(): string {
    return CredentialsService.facebookSocialUser ? 'url(\'http://graph.facebook.com/' + CredentialsService.facebookSocialUser.id + '/picture?type=large&redirect=true&width=500&height=500\')' : '';
  }

  constructor(private httpClient: HttpClient, private authService: SocialAuthService, private router: Router,
              private progressService: ProgressService) {
  }

  static get headers(): { headers: HttpHeaders } {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return {headers};
  }

  public static get instagramToken(): string | null {
    return localStorage.getItem('instagramToken');
  }

  public static set instagramToken(value: string) {
    if (!value) {
      localStorage.removeItem('instagramToken');
    } else {
      localStorage.setItem('instagramToken', value);
    }
  }

  public static get facebookSocialUser(): SocialUser | null {
    return JSON.parse(localStorage.getItem('facebookSocialUser')) as SocialUser;
  }

  public static set facebookSocialUser(value: SocialUser | null) {
    if (!value) {
      localStorage.removeItem('facebookSocialUser');
    } else {
      localStorage.setItem('facebookSocialUser', JSON.stringify(value));
    }
  }

  signInWithFacebook(): Observable<SocialUser> {
    const fbLoginOptions = {
      scope: 'email,user_photos',
      return_scopes: true
    };
    return fromPromise(
      this.authService.signIn(FacebookLoginProvider.PROVIDER_ID, fbLoginOptions)).pipe(
      switchMap(response => {
        CredentialsService.facebookSocialUser = response;
        return this.httpClient.get<{ token: string, profilePicture: string }>(ApiEndpoint.getLongLivedToken).pipe(
          tap((longLiveTokenResponse) => {
            const newUser = {...response};
            newUser.authToken = longLiveTokenResponse.token;
            newUser.photoUrl = longLiveTokenResponse.profilePicture;
            CredentialsService.facebookSocialUser = newUser;
          }),
          map((_) => response)
        );
      }),
    );
  }

  signOut(): Observable<any> {
    return fromPromise(
      this.authService.signOut(true).then()
    ).pipe(
      finalize(() => {
        CredentialsService.facebookSocialUser = null;
        this.router.navigate(['/login']).then();
      })
    );
  }

  signInWithInstagram(redirectLink?: string): void {
    window.location.href = `
      https://api.instagram.com/oauth/authorize?client_id=${environment.instagramClientId}&redirect_uri=${environment.instagramRedirectUri}&scope=user_profile,user_media&response_type=code${redirectLink ? `&state=` + redirectLink : ''}`;
  }

  sendMarketingConsent(agree: boolean): Observable<any> {
    const task = this.progressService.addTask('sendMarketingConsent');
    return this.httpClient.post(ApiEndpoint.sendMarketingConsentEndpoint, { accept_marketing_tos: agree }).pipe(
      finalize(() => this.progressService.removeTask(task.id))
    );
  }
}

<div class="modal-backdrop fade show"></div>
<div class="modal fade bd-example-modal-xl show layout-change-dialog" tabindex="-1" role="dialog" data-backdrop="static"
     aria-modal="true" style="display: block;" (click)="closeDialog.emit()" [style.cursor]="'pointer'">
  <div class="modal-dialog modal-dialog-centered" role="document" (click)="$event.stopPropagation();">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Change page layout</h5>
      </div>
      <div class="modal-body">
        <div class="layout-list" [ngClass]="layoutFormat">
          <!--1_1-->
          <div class="element">
            <div class="layout-holder" [ngClass]="{'selected': selectedLayout == '1_1'}" (click)="selectedLayout = '1_1'">
              <div class="layout">
                <div class="layout-item" [style.width]="'100%'" [style.height]="'100%'">
                  <div class="border-holder"></div>
                </div>
              </div>
            </div>
          </div>
          <!--2_1-->
          <div class="layout-holder" [ngClass]="{'selected': selectedLayout == '2_1'}" (click)="selectedLayout = '2_1'">
            <div class="layout">
              <div class="layout-item" [style.width]="'50%'" [style.height]="'100%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.width]="'50%'" [style.height]="'100%'">
                <div class="border-holder"></div>
              </div>
            </div>
          </div>
          <!--2_2-->
          <div class="layout-holder" [ngClass]="{'selected': selectedLayout == '2_2'}" (click)="selectedLayout = '2_2'">
            <div class="layout">
              <div class="layout-item" [style.height]="'50%'" [style.width]="'100%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'50%'" [style.width]="'100%'">
                <div class="border-holder"></div>
              </div>
            </div>
          </div>
          <!--3_1-->
          <div class="layout-holder" [ngClass]="{'selected': selectedLayout == '3_1'}" (click)="selectedLayout = '3_1'">
            <div class="layout">
              <div class="layout-item" [style.height]="'50%'" [style.width]="'50%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'50%'" [style.width]="'50%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'50%'" [style.width]="'100%'">
                <div class="border-holder"></div>
              </div>
            </div>
          </div>
          <!--3_2-->
          <div class="layout-holder" [ngClass]="{'selected': selectedLayout == '3_2'}" (click)="selectedLayout = '3_2'">
            <div class="layout">
              <div class="layout-item" [style.height]="'60%'" [style.width]="'50%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'60%'" [style.width]="'50%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'40%'" [style.width]="'100%'">
                <div class="border-holder"></div>
              </div>
            </div>
          </div>
          <!--3_3-->
          <div class="layout-holder" [ngClass]="{'selected': selectedLayout == '3_3'}" (click)="selectedLayout = '3_3'">
            <div class="layout">
              <div class="layout-item" [style.height]="'50%'" [style.width]="'40%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'50%'" [style.width]="'60%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'50%'" [style.width]="'100%'">
                <div class="border-holder"></div>
              </div>
            </div>
          </div>
          <!--4_1-->
          <div class="layout-holder" [ngClass]="{'selected': selectedLayout == '4_1'}" (click)="selectedLayout = '4_1'">
            <div class="layout">
              <div class="layout-item" [style.height]="'50%'" [style.width]="'40%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'50%'" [style.width]="'60%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'50%'" [style.width]="'60%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'50%'" [style.width]="'40%'">
                <div class="border-holder"></div>
              </div>
            </div>
          </div>
          <!--4_2-->
          <div class="layout-holder" [ngClass]="{'selected': selectedLayout == '4_2'}" (click)="selectedLayout = '4_2'">
            <div class="layout">
              <div class="layout-item" [style.height]="'55%'" [style.width]="'60%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'55%'" [style.width]="'40%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'45%'" [style.width]="'45%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'45%'" [style.width]="'55%'">
                <div class="border-holder"></div>
              </div>
            </div>
          </div>
          <!--4_3-->
          <div class="layout-holder" [ngClass]="{'selected': selectedLayout == '4_3'}" (click)="selectedLayout = '4_3'">
            <div class="layout">
              <div class="layout-item" [style.height]="'50%'" [style.width]="'60%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'50%'" [style.width]="'40%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'50%'" [style.width]="'40%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'50%'" [style.width]="'60%'">
                <div class="border-holder"></div>
              </div>
            </div>
          </div>
          <!--4_4-->
          <div class="layout-holder" [ngClass]="{'selected': selectedLayout == '4_4'}" (click)="selectedLayout = '4_4'">
            <div class="layout">
              <div class="layout-item" [style.height]="'50%'" [style.width]="'100%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'50%'" [style.width]="'33.333%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'50%'" [style.width]="'33.333%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'50%'" [style.width]="'33.333%'">
                <div class="border-holder"></div>
              </div>
            </div>
          </div>
          <!--5_1-->
          <div class="layout-holder" [ngClass]="{'selected': selectedLayout == '5_1'}" (click)="selectedLayout = '5_1'">
            <div class="layout">
              <div class="layout-item" [style.height]="'50%'" [style.width]="'33.333%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'50%'" [style.width]="'33.333%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'50%'" [style.width]="'33.333%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'50%'" [style.width]="'50%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'50%'" [style.width]="'50%'">
                <div class="border-holder"></div>
              </div>
            </div>
          </div>
          <!--5_2-->
          <div class="layout-holder" [ngClass]="{'selected': selectedLayout == '5_2'}" (click)="selectedLayout = '5_2'">
            <div class="layout flex-column">
              <div class="layout-item" [style.height]="'60%'" [style.width]="'50%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'40%'" [style.width]="'50%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'33.333%'" [style.width]="'50%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'33.333%'" [style.width]="'50%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'33.333%'" [style.width]="'50%'">
                <div class="border-holder"></div>
              </div>
            </div>
          </div>
          <!--5_3-->
          <div class="layout-holder" [ngClass]="{'selected': selectedLayout == '5_3'}" (click)="selectedLayout = '5_3'">
            <div class="layout">
              <div class="layout-item" [style.height]="'50%'" [style.width]="'50%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'50%'" [style.width]="'50%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'50%'" [style.width]="'33.333%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'50%'" [style.width]="'33.333%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'50%'" [style.width]="'33.333%'">
                <div class="border-holder"></div>
              </div>
            </div>
          </div>
          <!--6_1-->
          <div class="layout-holder" [ngClass]="{'selected': selectedLayout == '6_1'}" (click)="selectedLayout = '6_1'">
            <div class="layout">
              <div class="layout-item" [style.height]="'50%'" [style.width]="'33.333%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'50%'" [style.width]="'33.333%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'50%'" [style.width]="'33.333%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'50%'" [style.width]="'33.333%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'50%'" [style.width]="'33.333%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'50%'" [style.width]="'33.333%'">
                <div class="border-holder"></div>
              </div>
            </div>
          </div>
          <!--6_2-->
          <div class="layout-holder" [ngClass]="{'selected': selectedLayout == '6_2'}" (click)="selectedLayout = '6_2'">
            <div class="layout flex-column">
              <div class="layout-item" [style.height]="'100%'" [style.width]="'25%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'100%'" [style.width]="'25%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'25%'" [style.width]="'50%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'25%'" [style.width]="'50%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'25%'" [style.width]="'50%'">
                <div class="border-holder"></div>
              </div>
              <div class="layout-item" [style.height]="'25%'" [style.width]="'50%'">
                <div class="border-holder"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer" [style.justifyContent]="'space-between'">
        <button type="button" class="btn btn--outline" (click)="closeDialog.emit()">
          Cancel
        </button>
        <button type="button" class="btn btn--gradient" (click)="layoutChanged.emit(selectedLayout)"
                [disabled]="!selectedLayout">
          Change layout
        </button>
      </div>
    </div>
  </div>
</div>

import {Component, OnInit} from '@angular/core';
import {faChevronDown, faPlus} from '@fortawesome/free-solid-svg-icons';
import {SocialAuthService} from 'angularx-social-login';
import {HttpClient} from '@angular/common/http';
import {faCalendarAlt, faTrashAlt} from '@fortawesome/free-regular-svg-icons';
import {PhotoService} from '../../services/photo.service';
import {SocialAlbum, AlbumResponse} from '../../data/album';
import {Photo} from '../../data/book';
import {forkJoin, Observable, of} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CredentialsService} from '../../services/credentials.service';
import {BookService} from '../../services/book.service';
import {delay, finalize} from 'rxjs/operators';

@Component({
  selector: 'app-create-book',
  templateUrl: './create-book.component.html',
  styleUrls: ['./create-book.component.scss']
})
export class CreateBookComponent implements OnInit {
  faPlus: any = faPlus;
  faChevronDown: any = faChevronDown;
  faCalendarAlt: any = faCalendarAlt;
  faTrashAlt: any = faTrashAlt;
  maxDate = new Date();
  showSelectedPhotoLossWarningDialog = false;
  private nextTab: 'smart' | 'like' | 'all';
  private _dateRange: Date[];
  set dateRange(value: Date[]) {
    this._dateRange = value;
    if (this.facebookPictures) {
      this.getFacebookAlbums(Boolean(localStorage.getItem('showDialog')));
    } else if (this.instagramPictures) {
      this.getInstagramAlbums(Boolean(localStorage.getItem('showDialog')));
    }
  }

  get dateRange(): Date[] {
    return this._dateRange;
  }

  instagramErrorDialog = false;
  _activeIndex: number;
  get activeIndex(): number {
    return this._activeIndex;
  }

  set activeIndex(value: number) {
    this._activeIndex = value;
    localStorage.setItem('activeIndex', value.toString());
    if (value === 2) {
      this.photoService.getRecommendedOrientation(this.selectedTab === 'all' ? this.imageList : this.selectedImages)
        .subscribe((response) => {
          this.orientationList = response;
          this.selectedFormat = response[0].orientation;
        });
    }
  }

  private _instagramPictures = Boolean(localStorage.getItem('instagramPictures')) ?? false;
  get instagramPictures(): boolean {
    return this._instagramPictures;
  }

  set instagramPictures(value: boolean) {
    this._instagramPictures = value;
    localStorage.setItem('instagramPictures', value.toString());
  }

  private _facebookPictures = Boolean(localStorage.getItem('facebookPictures')) ?? false;
  get facebookPictures(): boolean {
    return this._facebookPictures;
  }

  set facebookPictures(value: boolean) {
    this._facebookPictures = value;
    localStorage.setItem('facebookPictures', value.toString());
  }

  private _showDialog = false;
  get showDialog(): boolean {
    return this._showDialog;
  }

  set showDialog(value: boolean) {
    this._showDialog = value;
    localStorage.setItem('showDialog', value.toString());
  }

  private _showInputDialog = Boolean(localStorage.getItem('showInputDialog')) ?? false;
  get showInputDialog(): boolean {
    return this._showInputDialog;
  }

  set showInputDialog(value: boolean) {
    this._showInputDialog = value;
    localStorage.setItem('showInputDialog', value.toString());
  }

  private _selectedAlbums: string[] =
    localStorage.getItem('selectedAlbums') !== null ? JSON.parse(localStorage.getItem('selectedAlbums')) : [];
  get selectedAlbums(): string[] {
    return this._selectedAlbums;
  }

  set selectedAlbums(value: string[]) {
    this._selectedAlbums = value;
    localStorage.setItem('selectedAlbums', JSON.stringify(value));
  }

  _selectedImages: Photo[] =
    localStorage.getItem('selectedImages') !== null ? JSON.parse(localStorage.getItem('selectedImages')) : [];
  get selectedImages(): Photo[] {
    return this._selectedImages;
  }

  set selectedImages(value: Photo[]) {
    this._selectedImages = value;
    localStorage.setItem('selectedImages', JSON.stringify(value));
  }

  albumResponse: AlbumResponse;
  imageList: Photo[] = [];
  orientationList: { name: string, url: string, orientation: string, price: number }[] = [];

  get pages(): number {
    const pageSize = Math.round((this.selectedTab === 'all' ? this.imageList.length : this.selectedImages.length) / 2.5);
    return Math.max(pageSize, 24);
  }

  private _sortDirection: 'asc' | 'desc' = localStorage.getItem('sortDirection') as any ?? 'desc';
  get sortDirection(): 'asc' | 'desc' {
    return this._sortDirection;
  }

  set sortDirection(value: 'asc' | 'desc') {
    this._sortDirection = value;
    localStorage.setItem('sortDirection', value);
    this.imageList = this.imageList.sort((a, b) => {
      return this.sortDirection === 'asc' ? a.createdTime.getTime() - b.createdTime.getTime() :
        b.createdTime.getTime() - a.createdTime.getTime();
    });
  }

  private _selectedFormat: string = localStorage.getItem('selectedFormat') as any;
  get selectedFormat(): string {
    return this._selectedFormat;
  }

  set selectedFormat(value: string) {
    this._selectedFormat = value;
    localStorage.setItem('selectedFormat', value);
  }

  private _selectedTab: 'smart' | 'like' | 'all' = localStorage.getItem('selectedTab') as any ?? 'smart';
  showMagicProgress = false;
  showGenerateProgress = false;
  get selectedTab(): 'smart' | 'like' | 'all' {
    return this._selectedTab;
  }

  set selectedTab(value: 'smart' | 'like' | 'all') {
    this._selectedTab = value;
    localStorage.setItem('selectedTab', value);
    switch (value) {
      case 'smart':
        this.selectedImages = this.imageList.slice().sort((a, b) => {
          return b.point - a.point;
        }).slice(0, 60);
        break;
      case 'like':
        this.selectedImages = this.imageList.slice().sort((a, b) => {
          return b.likeCount - a.likeCount;
        }).slice(0, 60);
        break;
      case 'all':
        break;
    }
  }

  constructor(private authService: SocialAuthService, private httpClient: HttpClient, private photoService: PhotoService,
              private activatedRoute: ActivatedRoute, public router: Router, private bookService: BookService) {
    this.activeIndex = Number(localStorage.getItem('activeIndex')) ?? 0;
    this.activatedRoute.params.subscribe((params) => {
      if (params.instagramToken) {
        CredentialsService.instagramToken = params.instagramToken;
      } else if (this.router.url.indexOf('instagram-login-failed') > -1) {
        this.instagramPictures = false;
        this.instagramErrorDialog = true;
        CredentialsService.instagramToken = undefined;
      }
    });
  }

  static eraseSave(): void {
    localStorage.removeItem('dialogTitle');
    localStorage.removeItem('selectedImages');
    localStorage.removeItem('selectedAlbums');
    localStorage.removeItem('selectedTab');
    localStorage.removeItem('activeIndex');
    localStorage.removeItem('showInputDialog');
    localStorage.removeItem('sortDirection');
    localStorage.removeItem('facebookPictures');
    localStorage.removeItem('instagramPictures');
    localStorage.removeItem('showDialog');
    localStorage.removeItem('selectedFormat');
  }

  ngOnInit(): void {
    if (Boolean(localStorage.getItem('showDialog')) === true) {
      if (Boolean(localStorage.getItem('facebookPictures'))) {
        this.getFacebookAlbums(Boolean(localStorage.getItem('showDialog')));
      } else if (Boolean(localStorage.getItem('instagramPictures'))) {
        this.getInstagramAlbums(Boolean(localStorage.getItem('showDialog')));
      }

      if (Number(localStorage.getItem('activeIndex')) > 0) {
        this.downloadImages(true);
      }
    }
  }

  getFacebookAlbums(showDialog?: boolean, forceGoBack?: boolean): void {
    this.facebookPictures = true;
    let request: Observable<AlbumResponse>;
    if (this.instagramPictures) {
      request = this.photoService.getAllAlbums(this.dateRange);
    } else {
      request = this.photoService.getFacebookAlbums(this.dateRange);
    }
    request.subscribe(
      (response) => {
        this.albumResponse = response;
        this.showDialog = showDialog ?? true;
        if ((this.instagramPictures || forceGoBack) && showDialog === undefined || showDialog === null) {
          this.activeIndex = 0;
          this.showDialog = true;
        }
      },
      () => {
        this.facebookPictures = false;
      });
  }

  getInstagramAlbums(showDialog?: boolean, forceGoBack?: boolean): void {
    this.instagramPictures = true;
    let request: Observable<AlbumResponse>;
    if (this.facebookPictures) {
      request = this.photoService.getAllAlbums(this.dateRange);
    } else {
      request = this.photoService.getInstagramAlbums(this.dateRange);
    }
    request.subscribe((response) => {
      this.albumResponse = response;
      this.showDialog = showDialog ?? true;
      if ((this.facebookPictures || forceGoBack) && showDialog === undefined || showDialog === null) {
        this.activeIndex = 0;
        this.showDialog = true;
      }
    });
  }

  toggleAlbum(album: SocialAlbum): void {
    const newSelectedAlbumList = [...this.selectedAlbums];
    if (newSelectedAlbumList.indexOf(album.id) > -1) {
      newSelectedAlbumList.splice(newSelectedAlbumList.indexOf(album.id), 1);
    } else {
      newSelectedAlbumList.push(album.id);
    }
    this.selectedAlbums = newSelectedAlbumList;
  }

  downloadImages(fromSave: boolean = false): void {
    if (!fromSave) {
      this.showMagicProgress = true;
    }
    this.photoService.getImages(this.selectedAlbums, this.instagramPictures).pipe(finalize(() => this.showMagicProgress = false))
      .subscribe((imageList => {
        this.imageList = imageList;
        this.sortDirection = this.sortDirection;
        if (!fromSave) {
          this.activeIndex = 1;
        }
        if (this.selectedImages.length > 0) {
          this.selectedImages = this.selectedImages.filter(selected => this.imageList.map(image => image.id === selected.id));
        } else {
          this.selectedTab = this.selectedTab;
        }
      }));
  }

  toggleImage(image: Photo): void {
    if (this.selectedTab !== 'all') {
      const newSelectedImages = [...this.selectedImages];
      if (newSelectedImages.find(selected => selected.id === image.id)) {
        newSelectedImages.splice(newSelectedImages.findIndex(selected => selected.id === image.id), 1);
      } else {
        newSelectedImages.push(image);
      }
      this.selectedImages = newSelectedImages;
    }
  }

  getPictureCountInSelectedAlbum(): number {
    let imageCount = 0;
    imageCount = this.selectedAlbums.map(selectedAlbumId => {
      const facebookAlbum = this.albumResponse.facebookAlbums?.find(facebookAlbumItem => facebookAlbumItem.id === selectedAlbumId);
      if (facebookAlbum) {
        return facebookAlbum.photoList.length;
      }
      const instagramAlbum = this.albumResponse.instagramAlbums?.find(instagramAlbumItem => instagramAlbumItem.id === selectedAlbumId);
      if (instagramAlbum) {
        return instagramAlbum.photoList.length;
      }
      if (this.albumResponse.createdAlbums) {
        const createdAlbum = this.albumResponse.createdAlbums.find(createdAlbumItem => createdAlbumItem.id === selectedAlbumId);
        if (createdAlbum) {
          return createdAlbum.photoList.length;
        }
      }
      return 0;
    }).reduce((previousValue: number, currentValue: number) => {
      return imageCount += currentValue;
    }, 0);
    return imageCount;
  }

  isImageSelected(image: Photo): boolean {
    return this.selectedImages.find(selectedImage => selectedImage.id === image.id) !== undefined;
  }

  createBook(title: string): void {
    this.showGenerateProgress = true;
    forkJoin([
      this.bookService.generateBook({
        orientation: this.selectedFormat,
        photoList: this.selectedTab === 'all' ? this.imageList : this.selectedImages,
        title,
        albumList: this.selectedAlbums
      }),
      of('progress').pipe(delay(5000))
    ]).pipe(finalize(() => this.showGenerateProgress = false)).subscribe((response => {
      CreateBookComponent.eraseSave();
      this.router.navigate([`/book/${response[0].id}/book`]).then();
    }));
  }

  changeTab(newTab: 'smart' | 'like' | 'all'): void {
    this.nextTab = newTab;
    this.showSelectedPhotoLossWarningDialog = true;
  }

  warningDialogClosed(isOkSelected: boolean): void {
    if (isOkSelected) {
      this.selectedTab = this.nextTab;
    }
    this.showSelectedPhotoLossWarningDialog = false;
  }
}

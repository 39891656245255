<div class="modal-backdrop fade show"></div>
<div class="modal fade bd-example-modal-xl show" tabindex="-1" role="dialog" data-backdrop="static"
     aria-modal="true" style="display: block;" (click)="closeDialog.emit()" [style.cursor]="'pointer'">
  <div class="modal-dialog modal-dialog-centered" role="document" (click)="$event.stopPropagation();">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" [innerHTML]="title"></h5>
      </div>
      <div class="modal-body">
        <form>
          <ng-container *ngIf="!multilineText">
            <input type="text" class="form-control" id="input" aria-describedby="titleHelp"
                   [maxlength]="maxLength"
                   [placeholder]="placeholder" [(ngModel)]="newDialogTitle" [ngModelOptions]="{standalone: true}">
            <small id="titleHelp" [style.color]="'#999999'">{{maxLength - (newDialogTitle ? newDialogTitle.length : 0)}}
              character{{newDialogTitle == undefined || newDialogTitle == null || (maxLength - newDialogTitle.length) > 1 ? 's' : ''}}
              remaining</small>
          </ng-container>
          <ng-container *ngIf="multilineText">
            <textarea type="text" class="form-control" id="inputTextarea" aria-describedby="titleHelp" rows="4"
                      [(ngModel)]="newDialogTitle" [ngModelOptions]="{standalone: true}">
            </textarea>
          </ng-container>
        </form>
      </div>
      <div class="modal-footer" [style.justifyContent]="'space-between'">
        <button type="button" class="btn btn--outline" (click)="closeDialog.emit()"
                *ngIf="negativeButtonText != undefined && negativeButtonText != null">
          {{negativeButtonText}}
        </button>
        <button type="button" class="btn btn--gradient"
                [ngClass]="{'btn--full-width': !negativeButtonText}"
                (click)="closeDialog.emit(newDialogTitle)"
                [disabled]="!newDialogTitle || newDialogTitle.trim().length == 0">
          {{positiveButtonText}}
        </button>
      </div>
    </div>
  </div>
</div>

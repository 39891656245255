import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-ok-dialog',
  templateUrl: './ok-dialog.component.html',
  styleUrls: ['./ok-dialog.component.scss']
})
export class OkDialogComponent implements OnInit {
  @Input() title = '';
  @Input() body = '';
  @Input() negativeButtonText: string;
  @Input() positiveButtonText = 'OK';
  @Output() closeDialog = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

}

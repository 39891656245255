import {Component, HostBinding} from '@angular/core';
import {ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {ProgressService} from './services/progress.service';
import {filter, map, mergeMap} from 'rxjs/operators';
import {PhotobookTitleService} from './services/photobook-title.service';
import {CredentialsService} from './services/credentials.service';
import {SocialAuthService} from 'angularx-social-login';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {GoogleTagManagerService} from 'angular-google-tag-manager';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('simpleFadeAnimation', [

      state('show', style({display: 'flex', opacity: 1})),
      state('hidden', style({display: 'none', opacity: 0})),

      transition('show => hidden', [animate(200, style({opacity: 0})), animate(0, style({display: 'none'}))]),
      transition('hidden => show', [animate(0, style({display: 'flex'})), animate(200, style({opacity: 1}))]),
    ])
  ]
})
export class AppComponent {
  showProgress = false;

  constructor(public router: Router, private activatedRoute: ActivatedRoute, public titleService: PhotobookTitleService,
              public progressService: ProgressService, private gtmService: GoogleTagManagerService) {
    progressService.taskInProgress.subscribe(taskInProgress =>
      this.showProgress = taskInProgress.length > 0
    );
    router.events.subscribe(event => {
      switch (true) {
        case event instanceof NavigationStart: {
          progressService.addTask((event as NavigationStart).url);
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          progressService.removeTaskByName((event as NavigationStart).url);
          break;
        }
        default: {
          break;
        }
      }
    });
    router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event) => {
        const gtmTag = {
          event: 'page',
          pageName: (event as NavigationEnd).url
        };
        this.gtmService.pushTag(gtmTag);
        document.getElementsByTagName('body').item(0).style.marginBottom =
          (event as NavigationEnd).url.indexOf('login') > -1 ? '0' : '50px';
        return this.activatedRoute;
      }),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    ).subscribe((event) => {
      this.titleService.setTitle(event.title);
      this.titleService.setDescription(event.description);
    });
  }

  @HostBinding('class.bottom-margin') bottomMargin = this.router.url.indexOf('login') === -1;
}

<header>
  <nav class="navbar navbar-expand">
    <div class="container">
      <div class="app-logo" [style.backgroundImage]="'url(\'/assets/img/pic_roc_logo.svg\')'"></div>
      <p class="title">
        Hi, {{ CredentialsService.facebookSocialUser ? CredentialsService.facebookSocialUser.firstName : 'there'}}</p>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <div class="profile-picture" [style.backgroundImage]="CredentialsService.profilePictureUrl"></div>
        <ul class="navbar-nav">
          <li class="nav-item dropdown" dropdown>
            <a id="navbarDropdown" class="nav-link dropdown-toggle" dropdownToggle href="" role="button"
               (click)="$event.preventDefault()"
               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <fa-icon [icon]="faChevronDown"></fa-icon>
              <span class="caret"></span>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown" *dropdownMenu>
              <a class="dropdown-item" href="" [routerLink]="'/profile'">Home</a>
              <a class="dropdown-item" href="">Orders</a>
              <a class="dropdown-item" href=""
                 (click)="$event.preventDefault(); credentialsService.signOut().subscribe()">Logout</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
<main [formGroup]="formGroup">
  <div class="modal-backdrop fade show"></div>
  <div class="modal fade bd-example-modal-xl show" tabindex="-1" role="dialog" data-backdrop="static"
       aria-modal="true" style="display: block;" *ngIf="book && configureData">
    <div class="modal-dialog modal-l" role="document">
      <div class="modal-content">
        <div class="modal-header my-4">
          <span class="title">Review your order</span>
        </div>
        <hr>
        <div class="modal-body">
          <div class="basket-header">
            <div class="book-cover" [style.backgroundImage]="'url(\'' + book.cover.photoList[0].url + '\')'">
            </div>
            <div class="text-container">
              <p class="mb-1">
                <strong>{{book?.title}}</strong>
              </p>
              <p>
                {{getBookData()}}<br>
                <button
                  class="btn btn--gradient"
                  [style.marginTop]="'0.5rem'"
                  [style.fontSize]="'0.7rem'"
                  (click)="goBackToEdit()">Edit</button>
              </p>
            </div>
          </div>
          <hr>
          <div class="special-offer-container">
            <div class="special-offer-label mobile">Special offer</div>
            <div class="grey-background-content">
              <div class="special-offer-label desktop">Special offer</div>
              <img src="./assets/img/icon-smiley-wink.png">
              <p>-15% if you buy X books or more!</p>
            </div>
          </div>
          <div class="rounded-border rounded-border--blue mb-3 d-flex product-count-setter-container">
            <div class="d-flex py-3 product-count-setter" *ngIf="priceOfLargeBook > 0">
              <div class="text-center" [style.width]="'120px'">
                {{book.orientation == 'square' ? 'Extra Large' : 'Large'}}
                <br>
                <ng-container *ngIf="book.orientation == 'square'">
                  (29.4 x 29.4 cm)
                </ng-container>
                <ng-container *ngIf="book.orientation == 'portrait'">
                  (21.0 x 29.7 cm)
                </ng-container>
                <ng-container *ngIf="book.orientation == 'landscape'">
                  (29.7 x 21.0 cm)
                </ng-container>
                <br>
                Base price: <strong>${{ priceOfLargeBook }}</strong>
              </div>
              <div [style.width]="'170px'">
                <div class="numberic-updown">
                  <button (click)="decreaseNumberOfLargeOrder()" class="fas fa-plus"
                          [disabled]="isNaN(Number(formGroup.controls.numberOfLargeOrder.value)) || formGroup.controls.numberOfLargeOrder.value == 0">
                    <fa-icon [icon]="faMinus"></fa-icon>
                  </button>
                  <input formControlName="numberOfLargeOrder" type="number" class="form-control text-center" value="1"
                         placeholder="0"
                         [style.borderColor]="formGroup.controls.numberOfLargeOrder.invalid ? 'red' : '#ced4da'">
                  <button (click)="increaseNumberOfLargeOrder()" class="fas fa-minus">
                    <fa-icon [icon]="faPlus"></fa-icon>
                  </button>
                </div>
              </div>
            </div>
            <div class="d-flex py-3 product-count-setter" *ngIf="priceOfSmallBook > 0">
              <div class="text-center" [style.width]="'120px'">
                {{book.orientation == 'square' ? 'Large' : 'Small'}}
                <br>
                <ng-container *ngIf="book.orientation == 'square'">
                  (21.0 x 21.0 cm)
                </ng-container>
                <ng-container *ngIf="book.orientation == 'portrait'">
                  (14.8 x 21.0 cm)
                </ng-container>
                <ng-container *ngIf="book.orientation == 'landscape'">
                  (21.0 x 14.8 cm)
                </ng-container>
                <br>
                Base price: <strong>${{ priceOfSmallBook }}</strong>
              </div>
              <div [style.width]="'170px'">
                <div class="numberic-updown">
                  <button (click)="decreaseNumberOfSmallOrder()" class="fas fa-plus"
                          [disabled]="isNaN(Number(formGroup.controls.numberOfSmallOrder.value)) || formGroup.controls.numberOfSmallOrder.value == 0">
                    <fa-icon [icon]="faMinus"></fa-icon>
                  </button>
                  <input formControlName="numberOfSmallOrder" type="number" class="form-control text-center" value="1"
                         placeholder="0"
                         [style.borderColor]="formGroup.controls.numberOfSmallOrder.invalid ? 'red' : '#ced4da'">
                  <button href="#" (click)="increaseNumberOfSmallOrder()" class="fas fa-minus">
                    <fa-icon [icon]="faPlus"></fa-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6"></div>
            <div class="col-md-6 col-12">
              <p class="d-flex justify-content-between mb-0 fs-full" *ngIf="finalPrice?.discountPrice > 0">
                <span>Coupon code</span> <strong>(-{{finalPrice.discountPercent}}%)
                -${{finalPrice?.discountPrice | number: '1.0-2'}}</strong></p>
              <p class="d-flex justify-content-between mb-0 fs-full"><span>Total</span></p>
              <p class="d-flex justify-content-between mb-2 md-4"><span class="smaller">including tax</span> <strong
                class="fs-full">${{finalPrice?.totalPrice | number:'1.0-2'}}</strong></p>
            </div>
          </div>
          <hr>
          <div class="row align-items-end mb-4">
            <div class="col-md-6 col-12">
              <div class="form-group" [style.marginBottom]="'0px'">
                <label for="couponCode" [style.fontSize]="'14px'">Do you have a coupon code?</label>
                <input type="text" class="form-control" id="couponCode" placeholder="FREESMALL" #couponCode>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <button type="button" class="btn btn--outline btn--full-width mt-3"
                      (click)="couponCode.value && couponCode.value.length > 0 ? formGroup.controls.couponCode.setValue(couponCode.value) : undefined">
                Submit
              </button>
            </div>
          </div>
          <div class="row align-items-end mb-4">
            <div class="col-md-6">
            </div>
            <div class="col-md-6 col-12">
              <button type="button" class="btn btn--gradient btn--full-width" (click)="goToCheckout()"
                      [disabled]="formGroup.invalid">
                <fa-icon [icon]="faShieldAlt" class="mr-2"></fa-icon>
                Secure checkout
              </button>
            </div>
          </div>
        </div>

        <div class="modal-separator">
          <h6 class="modal-title">About our secure checkout</h6>
        </div>

        <div class="modal-body text-center">
          <div class="row" [style.marginTop]="'15px'">
            <div class="col-md-6 col-12">
              <img src="/assets/img/icon-book-2.png" alt="" [style.marginBottom]="'1rem'"
                   [style.width]="'38px'">
              <p>
                <strong class="fs-full">Fully encrypted</strong> <br>
                Your information is secured using on <br>
                SSL/TLS <br>
                Certificate, so it is well protected.
              </p>
            </div>
            <div class="col-md-6 col-12">
              <img src="/assets/img/icon-shield.png" alt="" [style.marginBottom]="'1rem'"
                   [style.width]="'38px'">
              <p>
                <strong class="fs-full">Trusted banking services</strong> <br>
                All major payment services are <br>
                supported
              </p>
            </div>
            <div class="col-12">
              <p class="mt-4 fs-full" [style.fontSize]="'17px'">
                <fa-icon class="m-1" [icon]="faCcVisa"></fa-icon>
                <fa-icon class="m-1" [icon]="faCcMastercard"></fa-icon>
                <fa-icon class="m-1" [icon]="faCcAmex"></fa-icon>
                <fa-icon class="m-1" [icon]="faCcDiscover"></fa-icon>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

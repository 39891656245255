// tslint:disable-next-line:class-name
export class environment {
  static production = false;
  static serverEndpoint = 'https://test-pb.cobalttestdomain.hu';
  static instagramClientId = '458032005129896';
  static instagramRedirectUri = environment.serverEndpoint + '/api/instagram_login';
  static stripeKey = 'pk_test_Tl2igiYispiX0JWoANSlM7rs00AjEtYV9d';
  static gtmKey = 'GTM-57K8775';
  static facebookAppId = '690476204715038';
}

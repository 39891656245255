import {ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {CredentialsService} from '../../services/credentials.service';
import {Book, Page, PhotobookImage, PhotoData} from '../../data/book';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {BookService} from '../../services/book.service';
import {faAngleLeft, faArrowsAlt, faChevronDown, faLongArrowAltLeft, faLongArrowAltRight, faPencilAlt, faPlus} from '@fortawesome/free-solid-svg-icons';
import {tap} from 'rxjs/operators';
import {faTrashAlt} from '@fortawesome/free-regular-svg-icons';
import {v4 as uuidv4} from 'uuid';
import {ImageEditorComponent} from '../image-editor/image-editor.component';

@Component({
  selector: 'app-book-editor',
  templateUrl: './book-editor.component.html',
  styleUrls: ['./book-editor.component.scss']
})
export class BookEditorComponent {
  document = document;
  CredentialsService = CredentialsService;
  faChevronDown = faChevronDown;
  faArrowsAlt: any = faArrowsAlt;
  faAngleLeft: any = faAngleLeft;
  faPencilAlt: any = faPencilAlt;
  faTrashAlt: any = faTrashAlt;
  faLongArrowAltLeft: any = faLongArrowAltLeft;
  faLongArrowAltRight: any = faLongArrowAltRight;
  faPlus: any = faPlus;
  showInputDialog = false;
  book: Book;
  activeTab: 'book' | 'page' = 'book';
  activePageIndex = 0;
  getBookSubscription: Subscription;
  editing = false;
  showChangeLayoutDialog = false;
  showEmptyPageWarningDialog = false;
  showOrderBookDialog = false;
  editingLayoutElementData: {
    ratio: number;
    image: PhotobookImage;
    cover: boolean;
    backcover: boolean;
    imageIndex: number;
  };
  pageEditHistory: Page[] = [];
  instagramErrorDialog = false;
  @ViewChild(ImageEditorComponent) imageEditorComponent: ImageEditorComponent;
  get activePage(): Page {
    if (this.activePageIndex === -1) {
      return this.book?.cover;
    } else if (this.activePageIndex === -2) {
      return this.book?.backcover;
    } else {
      return this.book?.pages[this.activePageIndex];
    }
  }

  constructor(public credentialsService: CredentialsService, private activatedRoute: ActivatedRoute, public location: Location,
              private service: BookService, public router: Router, private changeDetectorRef: ChangeDetectorRef) {
    combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams]).subscribe(value => {
      const params = value[0];
      const queryParams = value[1];
      if (!this.getBookSubscription) {
        this.getBookSubscription = this.service.getBook(params.bookId).subscribe((response) => {
          if (response.status === 'editing' || response.status === undefined) {
            this.book = response;
          } else if (response.status === 'configured') {
            this.router.navigate(['/basket/' + response.id], {replaceUrl: true}).then();
          } else if (response.status === 'checkout') {
            this.router.navigate(['/checkout/' + response.id], {replaceUrl: true}).then();
          }
        });
      }

      if (queryParams.instagramLoginFailed) {
        this.instagramErrorDialog = true;
        CredentialsService.instagramToken = undefined;
        this.router.navigate([],
          {
            relativeTo: this.activatedRoute,
            queryParams: {
              showEditPage: undefined
            },
            queryParamsHandling: 'merge'
          }).then();
      }

      if (queryParams.instagramToken) {
        CredentialsService.instagramToken = queryParams.instagramToken;
        this.router.navigate([],
          {
            relativeTo: this.activatedRoute,
            queryParams: {
              instagramToken: undefined
            },
            queryParamsHandling: 'merge'
          }).then();
      }

      if (params.tab === 'book') {
        this.activeTab = 'book';
      } else if (params.tab === 'page') {
        this.activeTab = 'page';
        const pageParam = Number(queryParams.page);
        this.activePageIndex = isNaN(pageParam) ? 0 : pageParam;
        if (queryParams.showEditPage !== undefined) {
          this.editing = true;
          setTimeout(() => {
            const element = document.getElementsByClassName('image_edit_button_active_page');
            (element[queryParams.showEditPage] as any).click();
            setTimeout(() => {
              this.imageEditorComponent.changePhotoDialog = true;
            }, 300);
          }, 300);
        }
      } else {
        this.activeTab = 'book';
        this.location.go('/book/' + params.bookId + '/book');
      }
      this.pageEditHistory = [];
    });
  }

  editPhotobookName(event: any): void {
    event.preventDefault();
    this.showInputDialog = true;
  }

  changePage(event: any, forward: boolean): void {
    event.preventDefault();
    this.pageEditHistory = [];
    if (forward && this.activePageIndex !== -2 && this.activePageIndex + 1 <= this.book.pageCount - 1) {
      this.moveToPage(this.activePageIndex + 1);
    } else if (!forward && this.activePageIndex - 1 >= 0) {
      this.moveToPage(this.activePageIndex - 1);
    } else if (!forward && this.activePageIndex === 0) {
      this.moveToPage(-1);
    } else if (forward && this.activePageIndex === this.book.pageCount - 1) {
      this.moveToPage(-2);
    } else if (!forward && this.activePageIndex === -2) {
      this.moveToPage(this.book.pageCount - 1);
    } else if (forward && this.activePageIndex === -1) {
      this.moveToPage(0);
    }
  }

  postNewName(newDialogTitle: string): void {
    if (newDialogTitle && newDialogTitle.length > 0) {
      const newBook = JSON.parse(JSON.stringify(this.book));
      newBook.title = newDialogTitle;
      this.saveBook(newBook).subscribe(() => {
        this.showInputDialog = false;
      });
    } else {
      this.showInputDialog = false;
    }
  }

  setCover(url: string): void {
    const newBook = JSON.parse(JSON.stringify(this.book));
    newBook.cover.photoList[0].url = url;
    newBook.cover.photoList[0].frontendData = {
      size: 100,
      rotate: 0,
      fontPositionY: 0,
      fontPositionX: 0,
      backgroundPositionX: 0,
      backgroundPositionY: 0,
      fontSize: 100,
      fontColor: '#ffffff',
      fontText: ''
    };
    this.saveBook(newBook).subscribe();
  }

  setBackCover(url: string): void {
    const newBook = JSON.parse(JSON.stringify(this.book));
    newBook.backcover.photoList[0].url = url;
    newBook.backcover.photoList[0].frontendData = {
      size: 100,
      rotate: 0,
      fontPositionY: 0,
      fontPositionX: 0,
      backgroundPositionX: 0,
      backgroundPositionY: 0,
      fontSize: 100,
      fontColor: '#ffffff',
      fontText: ''
    };
    this.saveBook(newBook).subscribe();
  }

  listReordered(event: any): void {
    const newBook = JSON.parse(JSON.stringify(this.book));
    newBook.pages = event;
    this.service.saveBook(newBook).subscribe((response) => {
      this.book = response;
    });
  }

  deletePhoto(data: { pageIndex: number, imageIndex: number}): void {
    const newBook = JSON.parse(JSON.stringify(this.book));
    const pageElement = newBook.pages[data.pageIndex];
    const photoElement = pageElement.photoList[data.imageIndex];
    photoElement.url = '';
    photoElement.frontendData.size = 100;
    photoElement.frontendData.backgroundPositionX = 0;
    photoElement.frontendData.backgroundPositionY = 0;
    photoElement.frontendData.rotate = 0;
    this.service.saveBook(newBook).subscribe((response) => {
      this.book = response;
    });
  }

  saveBook(newBook?: Book): Observable<Book> {
    return this.service.saveBook(newBook ? newBook : this.book).pipe(
      tap((response) => {
        this.book = response;
      }));
  }

  savePage(newPage: Page, pushToHistory: boolean = true): void {
    const newBook = JSON.parse(JSON.stringify(this.book));
    const oldPage = JSON.parse(JSON.stringify(newBook.pages[newBook.pages.findIndex(page => page.pageNumber === newPage.pageNumber)]));
    newBook.pages[newBook.pages.findIndex(page => page.pageNumber === newPage.pageNumber)] = newPage;
    this.saveBook(newBook).subscribe(() => {
      if (pushToHistory) {
        this.pageEditHistory.push(oldPage);
      } else {
        this.pageEditHistory.pop();
      }
      this.editingLayoutElementData = undefined;
    });
  }

  getImageProperty(property: string, photobookImage?: PhotobookImage, imageHolder?: HTMLElement): string | undefined {
    if (photobookImage) {
      if (property.startsWith('backgroundPosition')) {
        const value = photobookImage.frontendData[property];
        let containerSize: number;
        if (property.endsWith('X')) {
          containerSize = imageHolder.offsetWidth;
        } else {
          containerSize = imageHolder.offsetHeight;
        }
        return `${property.endsWith('X') ? 'translateX' : 'translateY'}(${containerSize * (value / 100)}px)`;
      } else if (property === 'rotate') {
        return `rotate(${photobookImage.frontendData.rotate}deg)`;
      } else if (property === 'fontSize') {
        const containerSize = imageHolder.offsetHeight;
        return `${containerSize * (photobookImage.frontendData[property] / 100)}px`;
      } else if (property.startsWith('fontPosition')) {
        let containerSize: number;
        if (property.endsWith('X')) {
          containerSize = imageHolder.offsetWidth;
        } else {
          containerSize = imageHolder.offsetHeight;
        }
        return `${containerSize * (photobookImage.frontendData[property] / 100)}px`;
      } else {
        return photobookImage.frontendData[property];
      }
    } else {
      return '';
    }
  }

  openLayoutEditor(imageElement: any, image: PhotobookImage): void {
    this.editingLayoutElementData = {
      ratio: imageElement.offsetWidth / imageElement.offsetHeight,
      image,
      cover: this.activePageIndex === -1,
      backcover: this.activePageIndex === -2,
      imageIndex: Array.prototype.indexOf.call(imageElement.parentNode.parentNode.childNodes, imageElement.parentNode)
    };
  }

  onSaveLayoutElement(imageData: PhotoData, cover: boolean = false, backcover: boolean = false): void {
    const newBook = JSON.parse(JSON.stringify(this.book));
    let page: Page;
    if (cover || backcover) {
      page = cover ? newBook.cover : newBook.backcover;
      page.photoList[page.photoList.findIndex(photo => photo.id === this.editingLayoutElementData.image.id)].frontendData = imageData;
      this.saveBook(newBook).subscribe(() => {
        this.editingLayoutElementData = undefined;
      });
    } else {
      page = newBook.pages.find(pageItem => {
        return !!pageItem.photoList.find(photo => photo.id === this.editingLayoutElementData.image.id);
      });
      const newPhoto = JSON.parse(JSON.stringify(page));
      newPhoto.photoList[newPhoto.photoList.findIndex(photo =>
        photo.id === this.editingLayoutElementData.image.id)].frontendData = imageData;
      this.savePage(newPhoto);
    }
  }

  photoChanged(pageIndex: number, imageIndex: number, newPhoto: PhotobookImage, cover: boolean = false, backcover: boolean = false): void {
    const newBook = JSON.parse(JSON.stringify(this.book));
    let page: Page;
    if (cover || backcover) {
      page = cover ? newBook.cover : newBook.backcover;
      page.photoList[page.photoList.findIndex(photo => photo.id === this.editingLayoutElementData.image.id)] = newPhoto;
      this.saveBook(newBook).subscribe(() => {
        this.editingLayoutElementData = undefined;
      });
    } else {
      const photo = JSON.parse(JSON.stringify(newBook.pages[pageIndex]));
      photo.photoList[imageIndex] = newPhoto;
      this.savePage(photo);
    }
  }

  layoutChange(newLayout: '1_1' | '2_1' | '2_2' | '3_1' | '3_2' | '3_3' | '4_1' | '4_2' | '4_3' | '4_4' | '5_1' | '5_2' | '5_3' | '6_1' | '6_2'): void {
    const newBook = JSON.parse(JSON.stringify(this.book));
    newBook.pages[this.activePageIndex].layout = newLayout;
    this.saveBook(newBook).subscribe(() => {
      this.showChangeLayoutDialog = false;
    });
  }

  addPage(page: Page): void {
    const pageIndex = this.book.pages.findIndex(item => item.pageNumber === page.pageNumber);
    const newPageList = [
      ...this.book.pages.slice(0, pageIndex),
      ...[
        {
          pageNumber: Math.max(this.book.backcover.pageNumber, Math.max(...this.book.pages.map(item => item.pageNumber))) + 1,
          layout: '1_1',
          photoList: [
            {
              frontendData: {
                fontText: '',
                fontColor: '#FFFFFF',
                fontSize: 100,
                fontPositionX: 0,
                fontPositionY: 0,
                backgroundPositionY: 0,
                backgroundPositionX: 0,
                rotate: 0,
                size: 0
              },
              url: '',
              id: uuidv4(),
              photoNumber: 1
            }
          ],
          photosNumber: 1
        },
        {
          pageNumber: Math.max(this.book.backcover.pageNumber, Math.max(...this.book.pages.map(item => item.pageNumber))) + 2,
          layout: '1_1',
          photoList: [
            {
              frontendData: {
                fontText: '',
                fontColor: '#FFFFFF',
                fontSize: 100,
                fontPositionX: 0,
                fontPositionY: 0,
                backgroundPositionY: 0,
                backgroundPositionX: 0,
                rotate: 0,
                size: 0
              },
              url: '',
              id: uuidv4(),
              photoNumber: 1
            }
          ],
          photosNumber: 1
        }
      ],
      ...this.book.pages.slice(pageIndex)
    ] as any;
    this.saveBook({...this.book, ...{pages: newPageList}}).subscribe();
  }

  deletePage(page: Page): void {
    const pageIndex = this.book.pages.findIndex(item => item.pageNumber === page.pageNumber);
    const newPageList = [...this.book.pages];
    newPageList.splice(pageIndex, 2);
    this.saveBook({...this.book, ...{pages: newPageList}}).subscribe();
  }

  moveToPage(index: number): void {
    this.router.navigate([`/book/${this.book?.id}/page`], {
      queryParams: {
        page: index
      }
    }).then();
  }

  undo(): void {
    this.savePage(this.pageEditHistory[this.pageEditHistory.length - 1], false);
    this.changeDetectorRef.detectChanges();
  }

  goToConfigure(checkEmptyPage: boolean = true): void {
    if (checkEmptyPage) {
      if (this.book.pages.find(page => page.photoList.find(photo => !photo.url || photo.url.length === 0))) {
        this.showEmptyPageWarningDialog = true;
      } else {
        this.showOrderBookDialog = true;
      }
    } else {
      this.showEmptyPageWarningDialog = false;
      this.showOrderBookDialog = true;
    }
  }

  getImageForPageViewSide(): Page {
    const index = this.activePageIndex % 2 === 0 ? this.activePageIndex + 1 : this.activePageIndex - 1;
    return this.book?.pages[index];
  }

  getPrintViewHtml(printView: HTMLDivElement): string[] {
    const printViewList = [];
    Object.keys(printView.children).forEach(key => {
      printViewList.push(printView.children[key].outerHTML);
    });
    return printViewList;
  }
}

import {Injectable} from '@angular/core';
import {CredentialsService} from './credentials.service';
import {Observable, throwError} from 'rxjs';
import {AlbumResponse} from '../data/album';
import {HttpClient} from '@angular/common/http';
import {Book, GenerateBook, Photo} from '../data/book';
import {ApiEndpoint} from '../../environments/api-endpoint';
import {PhotobookTask, ProgressService} from './progress.service';
import {finalize, map} from 'rxjs/operators';
import {DatePipe} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PhotoService {

  constructor(private httpClient: HttpClient, private credentialsService: CredentialsService, private progressService: ProgressService,
              private datePipe: DatePipe) {
  }

  getAllAlbums(dateRange?: Date[]): Observable<AlbumResponse> {
    return this._getAlbums(true, true, dateRange);
  }

  getFacebookAlbums(dateRange?: Date[]): Observable<AlbumResponse> {
    return this._getAlbums(true, false, dateRange);
  }

  getInstagramAlbums(dateRange?: Date[]): Observable<AlbumResponse> {
    return this._getAlbums(false, true, dateRange);
  }

  _getAlbums(facebook: boolean, instagram: boolean, dateRange?: Date[], fromBook: boolean = false): Observable<AlbumResponse> {
    if (facebook && !CredentialsService.facebookSocialUser) {
      this.credentialsService.signInWithFacebook().subscribe(() => {
        return this._getAlbums(facebook, instagram, dateRange);
      });
    }
    if (instagram && !CredentialsService.instagramToken) {
      this.credentialsService.signInWithInstagram();
      throwError('Instagram login started');
    }
    const task = this.progressService.addTask('getAlbums');
    return this.httpClient.get<AlbumResponse>(`${ApiEndpoint.getAlbumsEndpoint}?fromBook=${fromBook}&facebook=${facebook}&instagram=${instagram}${dateRange && dateRange.length > 0 ? ('&since=' + dateRange[0].getTime() + '&until=' + dateRange[1].getTime()) : ''}`).pipe(
      finalize(() => this.progressService.removeTask(task.id))
    );
  }

  getImages(albumIdList: string[] = [], instagram: boolean = false, bookId?: number, pageIndex?: number, imageIndex?: number):
    Observable<Photo[]> {
    const task = this.progressService.addTask('getImages');
    let observable: Observable<any>;
    if (albumIdList.length > 0 && !instagram) {
      if (CredentialsService.facebookSocialUser) {
        observable = this.httpClient.get<Photo[]>(`${ApiEndpoint.getPhotosEndpoint}?albumIdList=${albumIdList}`);
      } else {
        this.credentialsService.signInWithFacebook().subscribe(() => {
          observable = this.getImages(albumIdList, instagram);
        });
      }
    } else if (albumIdList.length > 0 && instagram) {
      if (CredentialsService.facebookSocialUser && CredentialsService.instagramToken) {
        observable = this.httpClient.get<Photo[]>(`${ApiEndpoint.getPhotosEndpoint}?` +
          `albumIdList=${albumIdList}`);
      } else if (!CredentialsService.instagramToken) {
        this.credentialsService.signInWithInstagram(bookId ?
          `/book/${bookId}/page?page=${pageIndex}|showEditPage=${imageIndex}` : undefined);
        observable = throwError('Instagram login started');
      } else if (!CredentialsService.facebookSocialUser) {
        this.credentialsService.signInWithFacebook().subscribe(() => {
          observable = this.getImages(albumIdList, instagram);
        });
      }
    } else if (albumIdList.length === 0 && instagram) {
      if (CredentialsService.instagramToken) {
        observable = this.httpClient.get<Photo[]>(`${ApiEndpoint.getPhotosEndpoint}?albumIdList=${albumIdList}`);
      } else {
        this.credentialsService.signInWithInstagram(bookId ?
          `/book/${bookId}/page?page=${pageIndex}|showEditPage=${imageIndex}` : undefined);
        observable = throwError('Instagram login started');
      }
    } else {
      observable = throwError('Either facebook album list or instagram must present');
    }
    return observable.pipe(
      map((response: any) => {
        if (Array.isArray(response)) {
          return response.map(photo => {
            if (photo.createdTime) {
              const splittedDate = this.datePipe.transform(photo.createdTime, 'yyyy-M-dd-HH-mm-ss').split('-');
              photo.createdTime = new Date(Number(splittedDate[0]), Number(splittedDate[1]) - 1, Number(splittedDate[2]),
                Number(splittedDate[3]), Number(splittedDate[4]), Number(splittedDate[5]));
            }
            return photo;
          });
        } else {
          return response;
        }
      }),
      finalize(() => this.progressService.removeTask(task.id))
    );
  }

  getRecommendedOrientation(photoList: Photo[]): Observable<{ name: string; url: string; orientation: string; price: number }[]> {
    const task = this.progressService.addTask('getRecommendedOrientation');
    return this.httpClient.post<{ name: string, url: string, orientation: string, price: number }[]>(
      ApiEndpoint.getRecommendedOrientationEndpoint,
      {photoList}
    ).pipe(
      finalize(() => this.progressService.removeTask(task.id))
    );
  }
}

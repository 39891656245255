import {Component, OnInit} from '@angular/core';
import {faChevronDown, faPencilAlt} from '@fortawesome/free-solid-svg-icons';
import {Book, Page} from '../../data/book';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {CredentialsService} from '../../services/credentials.service';
import {SocialAlbum} from '../../data/album';
import {PhotoService} from '../../services/photo.service';
import {CreateBookComponent} from '../create-book/create-book.component';
import {BookService} from '../../services/book.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  CredentialsService: any = CredentialsService;
  faPencilAlt = faPencilAlt;
  faChevronDown = faChevronDown;
  CreateBookComponent = CreateBookComponent;
  activePage: 'books' | 'photos' = 'books';
  bookList: Book[] = [];
  albumList: SocialAlbum[] = [];
  showDialog = false;
  showNoPhotoDialog = false;

  constructor(private activatedRoute: ActivatedRoute, private location: Location, public credentialsService: CredentialsService,
              private photoService: PhotoService, private bookService: BookService, public router: Router) {
    this.bookService.getBooks().subscribe(response => this.bookList = response);
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      if (params.tab === 'books' || params.tab === 'photos') {
        this.activePage = params.tab;
      } else {
        this.activePage = 'books';
        this.location.go('/profile/books');
      }
    });
    this.photoService._getAlbums(CredentialsService.facebookSocialUser !== null,
      CredentialsService.instagramToken !== null, undefined, true)
      .subscribe((response) => {
        this.albumList = [...response.facebookAlbums];
        if (response.instagramAlbums) {
          this.albumList.push(...response.instagramAlbums);
        }
    });
  }

  createBook(): void {
    if (localStorage.getItem('facebookPictures') || localStorage.getItem('instagramPictures')) {
      this.showDialog = true;
    } else {
      CreateBookComponent.eraseSave();
      this.router.navigate(['/create-book']).then();
    }
  }

  getBookPhotoCount(book: Book): string {
    let imageCount = 0;
    book.pages.reduce((previousValue: number, currentValue: Page) => {
      return imageCount += currentValue.photosNumber;
    }, 0);
    return ` (${imageCount} photo${imageCount > 1 ? 's)' : ')'}`;
  }

  createBookFromAlbum(album: SocialAlbum): void {
    CreateBookComponent.eraseSave();
    if (album.source === 'facebook') {
      localStorage.setItem('facebookPictures', 'true');
      localStorage.removeItem('instagramPictures');
    } else if (album.source === 'instagram') {
      localStorage.setItem('instagramPictures', 'true');
      localStorage.removeItem('facebookPictures');
    }
    localStorage.setItem('activeIndex', '0');
    localStorage.setItem('showDialog', 'true');
    localStorage.setItem('selectedAlbums', JSON.stringify([album.id]));
    this.router.navigate(['/create-book']).then();
  }
}

<div class="modal-backdrop fade show" (click)="handleCloseDialog()"></div>
<div class="modal fade bd-example-modal-xl show" tabindex="-1" role="dialog" data-backdrop="static"
     aria-modal="true" style="display: block;" (click)="$event.stopPropagation();">
  <div class="modal-dialog" role="document" (click)="$event.stopPropagation();">
    <div class="modal-content">
      <div class="modal-body">
        <div class="change-photo-header">
          <p *ngIf="originalPhoto.url">Let's change this photo</p>
          <div *ngIf="originalPhoto.url" class="original-image" [style.backgroundImage]="'url(\'' + originalPhoto.url + '\')'"></div>
          <div class="row justify-content-center">
            <div class="col-lg-7 col-8 pl-0">
              <div class="form-group mb-0">
                <select class="form-control" id="shortBy" [(ngModel)]="sortDirection">
                  <option [value]="'desc'">Sort by date (descending)</option>
                  <option [value]="'asc'">Sort by date (ascending)</option>
                </select>
                <fa-icon [icon]="faChevronDown"></fa-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md">
            <ul class="image-list">
              <li [ngClass]="{'selected': image == selectedPhoto, 'original': image.url == originalPhoto.url}"
                  *ngFor="let image of imageList" (click)="selectImage(image)">
                <div class="single-image" [style.backgroundImage]="'url(\'' + image.url + '\')'"></div>
                <div class="overlay"></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<footer class="footer footer--modal-footer bg-white image-modal-footer">
  <div class="row justify-content-center align-items-center" [style.height]="'100%'">
    <button type="button" class="btn btn--outline btn--full-width" (click)="handleCloseDialog()" [style.border]="'none'">
      Cancel
    </button>
    <button type="button" class="btn btn--gradient btn--full-width" (click)="handlePhotoChanged()"
            [disabled]="!selectedPhoto">
      Change
    </button>
  </div>
</footer>

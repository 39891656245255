import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {faChevronDown, faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import {FormArray, FormBuilder, FormControl, Validators} from '@angular/forms';
import {OrderService} from '../../services/order.service';
import {CalculatePriceResponse, CheckoutResponse, ConfigureData} from '../../data/order';
import {forkJoin, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {BookService} from '../../services/book.service';
import {Book} from '../../data/book';
import {countryCodes} from 'src/app/data/country-codes';
import {fromPromise} from 'rxjs/internal-compatibility';
import {finalize} from 'rxjs/operators';
import {ProgressService} from '../../services/progress.service';
import {faPlusSquare} from '@fortawesome/free-regular-svg-icons';
import {environment} from '../../../environments/environment';

declare var google: any;
declare var Stripe: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, AfterViewInit {
  faChevronLeft: any = faChevronLeft;
  faChevronDown: any = faChevronDown;
  faPlusSquare: any = faPlusSquare;
  tab: 'shipping' | 'payment' = 'shipping';
  countryCodes = countryCodes;
  @ViewChild('placeAutocomplete') placeAutocomplete: ElementRef;
  form = this.formBuilder.group({
    email: [null, [Validators.required, Validators.email]],
    firstName: [null, Validators.required],
    lastName: [null, Validators.required],
    company: null,
    countryCode: [null, Validators.required],
    zip: [null, Validators.required],
    city: [null, Validators.required],
    address: [null, Validators.required],
    state: [null, Validators.required],
    book: [null, Validators.required],
    differentInvoiceAddress: false,
    invoiceCountryCode: null,
    invoiceZip: null,
    invoiceCity: null,
    invoiceAddress: null,
    invoiceState: null,
    acceptTerms: [false, Validators.requiredTrue],
    reviewedBooks: [false, Validators.requiredTrue],
    shippingType: [null, Validators.required],
  });
  subscription: Subscription;
  configureData: ConfigureData;
  manualAddressInput = false;
  finalPrice: CalculatePriceResponse;

  stripeError: string;

  checkoutErrorMessage: string;
  showErrorDialog = false;
  checkoutResponse: CheckoutResponse;

  get shippingCost(): number {
    return this.configureData?.shippingTypes?.find(item =>
      item.id === (this.form.controls.book.value as Book)?.orderInfo.shippingType)?.price;
  }

  get shippingTime(): string {
    return this.configureData?.shippingTypes?.find(item =>
      item.id === (this.form.controls.book.value as Book)?.orderInfo.shippingType)?.time;
  }

  friendEmailFormArray = new FormArray([
    new FormControl(null, [Validators.email, Validators.required])
  ]);

  stripe: any;
  card: any;

  constructor(private formBuilder: FormBuilder, public orderService: OrderService, private activatedRoute: ActivatedRoute,
              private bookService: BookService, private progressService: ProgressService, private router: Router,
              private ref: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.form.controls.differentInvoiceAddress.valueChanges.subscribe(change => {
      if (change) {
        this.form.controls.invoiceCountryCode.setValidators([Validators.required]);
        this.form.controls.invoiceZip.setValidators([Validators.required]);
        this.form.controls.invoiceCity.setValidators([Validators.required]);
        this.form.controls.invoiceAddress.setValidators([Validators.required]);
        this.form.controls.invoiceState.setValidators([Validators.required]);
      } else {
        this.form.controls.invoiceCountryCode.setValidators(null);
        this.form.controls.invoiceZip.setValidators(null);
        this.form.controls.invoiceCity.setValidators(null);
        this.form.controls.invoiceAddress.setValidators(null);
        this.form.controls.invoiceState.setValidators(null);
      }
      this.form.controls.invoiceCountryCode.updateValueAndValidity();
      this.form.controls.invoiceZip.updateValueAndValidity();
      this.form.controls.invoiceCity.updateValueAndValidity();
      this.form.controls.invoiceAddress.updateValueAndValidity();
      this.form.controls.invoiceState.updateValueAndValidity();
    });
    this.form.controls.shippingType.valueChanges.subscribe(change => {
      const newBook: Book = {...this.form.controls.book.value};
      newBook.orderInfo.shippingType = change;
      this.form.controls.book.setValue(newBook);
      this.orderService.calculatePrice(this.form.controls.book.value).subscribe(response => {
        this.finalPrice = response;
      });
    });
    this.activatedRoute.params.subscribe(params => {
      this.subscription?.unsubscribe();
      this.subscription = forkJoin([
        this.orderService.getConfigureData(),
        this.bookService.getBook(params.bookId),
      ]).subscribe((response) => {
        if (response[1].status === 'editing' || response[1].status === undefined) {
          this.router.navigate(['/book/' + response[1].id + '/book'], {replaceUrl: true}).then();
        } else if (response[1].status === 'configured') {
          this.router.navigate(['/basket/' + response[1].id], {replaceUrl: true}).then();
        } else if (response[1].status === 'checkout') {
          this.form.controls.book.setValue(response[1]);
          this.configureData = response[0];
          this.form.controls.shippingType.setValue(this.configureData.shippingTypes[0].id);
          this.orderService.calculatePrice(this.form.controls.book.value).subscribe(priceResponse => {
            this.finalPrice = priceResponse;
          });
        }
      });
    });

    // Your Stripe public key
    this.stripe = Stripe(environment.stripeKey);

    // Create `card` element that will watch for updates
    // and display error messages
    const elements = this.stripe.elements();
    this.card = elements.create('cardNumber');
    this.card.mount('#cardNumberElement');
    const expiry = elements.create('cardExpiry');
    expiry.mount('#cardExpiryDate');
    const cvc = elements.create('cardCvc');
    cvc.mount('#paymentCVC');
    this.card.addEventListener('change', event => {
      if (event.error) {
        this.stripeError = event.error.message;
      } else {
        this.stripeError = '';
      }
    });
  }

  ngAfterViewInit(): void {
    const autocomplete = new google.maps.places.Autocomplete(this.placeAutocomplete.nativeElement);
    autocomplete.setFields(['address_components']);
    autocomplete.addListener(
      'place_changed',
      () => {
        const place = autocomplete.getPlace();
        const country = place.address_components.find(component => component.types.indexOf('country') > -1);
        this.form.controls.countryCode.setValue(country?.short_name);
        const postalCode = place.address_components.find(component => component.types.indexOf('postal_code') > -1);
        this.form.controls.zip.setValue(postalCode?.long_name);
        const city = place.address_components.find(component => component.types.indexOf('locality') > -1);
        this.form.controls.city.setValue(city?.long_name);
        const address = place.address_components.find(component => component.types.indexOf('route') > -1);
        const streetNumber = place.address_components.find(component => component.types.indexOf('street_number') > -1);
        this.form.controls.address.setValue(address && streetNumber ? (`${address.long_name} ${streetNumber.long_name}`) : null);
        const state = place.address_components.find(component => component.types.indexOf('administrative_area_level_1') > -1);
        this.form.controls.state.setValue(state?.long_name);
        this.manualAddressInput = true;
        this.ref.detectChanges();
      });
  }

  checkout(): void {
    const task = this.progressService.addTask('checkout');
    fromPromise<any>(this.stripe.createToken(this.card)).pipe(
      finalize(() => this.progressService.removeTask(task.id))
    ).subscribe(tokenResponse => {
        if (tokenResponse?.token?.id) {
          const request = this.form.getRawValue();
          request.stripeToken = tokenResponse.token.id;
          this.orderService.checkout(request).subscribe((response) => {
              this.checkoutResponse = response;
            },
            () => {
              this.checkoutErrorMessage = undefined;
              this.showErrorDialog = true;
            });
        } else {
          this.checkoutErrorMessage = tokenResponse?.error?.message;
          this.showErrorDialog = true;
        }
      },
      error => {
        this.checkoutErrorMessage = error.message;
        this.showErrorDialog = true;
      });
  }

  addFriendFormControl(): void {
    this.friendEmailFormArray.push(new FormControl(null, [Validators.email, Validators.required]));
  }

  goBackToBasket(): void {
    const request = {...this.form.controls.book.value};
    request.status = 'configured';
    this.bookService.saveBook(request).subscribe((response) => {
      this.router.navigate(['/basket/' + response.id], {replaceUrl: true}).then();
    });
  }

  shareDiscount(): void {
    this.orderService.shareDiscount(this.friendEmailFormArray.getRawValue()).subscribe(() => {
      this.friendEmailFormArray.clear();
      this.addFriendFormControl();
    });
  }
}

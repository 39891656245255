export const countryCodes = [
  {countryName: 'Afghanistan', countryCode: 'AF'},
  {countryName: 'Albania', countryCode: 'AL'},
  {countryName: 'Algeria', countryCode: 'DZ'},
  {countryName: 'Andorra', countryCode: 'AD'},
  {countryName: 'Angola', countryCode: 'AO'},
  {countryName: 'Antarctica', countryCode: 'AQ'},
  {countryName: 'Argentina', countryCode: 'AR'},
  {countryName: 'Armenia', countryCode: 'AM'},
  {countryName: 'Aruba', countryCode: 'AW'},
  {countryName: 'Australia', countryCode: 'AU'},
  {countryName: 'Austria', countryCode: 'AT'},
  {countryName: 'Azerbaijan', countryCode: 'AZ'},
  {countryName: 'Bahrain', countryCode: 'BH'},
  {countryName: 'Bangladesh', countryCode: 'BD'},
  {countryName: 'Belarus', countryCode: 'BY'},
  {countryName: 'Belgium', countryCode: 'BE'},
  {countryName: 'Belize', countryCode: 'BZ'},
  {countryName: 'Benin', countryCode: 'BJ'},
  {countryName: 'Bhutan', countryCode: 'BT'},
  {countryName: 'Bolivia', countryCode: 'BO'},
  {countryName: 'Bosnia and Herzegovina', countryCode: 'BA'},
  {countryName: 'Botswana', countryCode: 'BW'},
  {countryName: 'Brazil', countryCode: 'BR'},
  {countryName: 'British Indian Ocean Territory', countryCode: 'IO'},
  {countryName: 'Brunei', countryCode: 'BN'},
  {countryName: 'Bulgaria', countryCode: 'BG'},
  {countryName: 'Burkina Faso', countryCode: 'BF'},
  {countryName: 'Burundi', countryCode: 'BI'},
  {countryName: 'Cambodia', countryCode: 'KH'},
  {countryName: 'Cameroon', countryCode: 'CM'},
  {countryName: 'Canada', countryCode: 'CA'},
  {countryName: 'Cape Verde', countryCode: 'CV'},
  {countryName: 'Central African Republic', countryCode: 'CF'},
  {countryName: 'Chad', countryCode: 'TD'},
  {countryName: 'Chile', countryCode: 'CL'},
  {countryName: 'China', countryCode: 'CN'},
  {countryName: 'Christmas Island', countryCode: 'CX'},
  {countryName: 'Cocos Islands', countryCode: 'CC'},
  {countryName: 'Colombia', countryCode: 'CO'},
  {countryName: 'Comoros', countryCode: 'KM'},
  {countryName: 'Cook Islands', countryCode: 'CK'},
  {countryName: 'Costa Rica', countryCode: 'CR'},
  {countryName: 'Croatia', countryCode: 'HR'},
  {countryName: 'Cuba', countryCode: 'CU'},
  {countryName: 'Curacao', countryCode: 'CW'},
  {countryName: 'Cyprus', countryCode: 'CY'},
  {countryName: 'Czech Republic', countryCode: 'CZ'},
  {countryName: 'Democratic Republic of the Congo', countryCode: 'CD'},
  {countryName: 'Denmark', countryCode: 'DK'},
  {countryName: 'Djibouti', countryCode: 'DJ'},
  {countryName: 'East Timor', countryCode: 'TL'},
  {countryName: 'Ecuador', countryCode: 'EC'},
  {countryName: 'Egypt', countryCode: 'EG'},
  {countryName: 'El Salvador', countryCode: 'SV'},
  {countryName: 'Equatorial Guinea', countryCode: 'GQ'},
  {countryName: 'Eritrea', countryCode: 'ER'},
  {countryName: 'Estonia', countryCode: 'EE'},
  {countryName: 'Ethiopia', countryCode: 'ET'},
  {countryName: 'Falkland Islands', countryCode: 'FK'},
  {countryName: 'Faroe Islands', countryCode: 'FO'},
  {countryName: 'Fiji', countryCode: 'FJ'},
  {countryName: 'Finland', countryCode: 'FI'},
  {countryName: 'France', countryCode: 'FR'},
  {countryName: 'French Polynesia', countryCode: 'PF'},
  {countryName: 'Gabon', countryCode: 'GA'},
  {countryName: 'Gambia', countryCode: 'GM'},
  {countryName: 'Georgia', countryCode: 'GE'},
  {countryName: 'Germany', countryCode: 'DE'},
  {countryName: 'Ghana', countryCode: 'GH'},
  {countryName: 'Gibraltar', countryCode: 'GI'},
  {countryName: 'Greece', countryCode: 'GR'},
  {countryName: 'Greenland', countryCode: 'GL'},
  {countryName: 'Guatemala', countryCode: 'GT'},
  {countryName: 'Guinea', countryCode: 'GN'},
  {countryName: 'Guinea-Bissau', countryCode: 'GW'},
  {countryName: 'Guyana', countryCode: 'GY'},
  {countryName: 'Haiti', countryCode: 'HT'},
  {countryName: 'Honduras', countryCode: 'HN'},
  {countryName: 'Hong Kong', countryCode: 'HK'},
  {countryName: 'Hungary', countryCode: 'HU'},
  {countryName: 'Iceland', countryCode: 'IS'},
  {countryName: 'India', countryCode: 'IN'},
  {countryName: 'Indonesia', countryCode: 'ID'},
  {countryName: 'Iran', countryCode: 'IR'},
  {countryName: 'Iraq', countryCode: 'IQ'},
  {countryName: 'Ireland', countryCode: 'IE'},
  {countryName: 'Israel', countryCode: 'IL'},
  {countryName: 'Italy', countryCode: 'IT'},
  {countryName: 'Ivory Coast', countryCode: 'CI'},
  {countryName: 'Japan', countryCode: 'JP'},
  {countryName: 'Jordan', countryCode: 'JO'},
  {countryName: 'Kazakhstan', countryCode: 'KZ'},
  {countryName: 'Kenya', countryCode: 'KE'},
  {countryName: 'Kiribati', countryCode: 'KI'},
  {countryName: 'Kosovo', countryCode: 'XK'},
  {countryName: 'Kuwait', countryCode: 'KW'},
  {countryName: 'Kyrgyzstan', countryCode: 'KG'},
  {countryName: 'Laos', countryCode: 'LA'},
  {countryName: 'Latvia', countryCode: 'LV'},
  {countryName: 'Lebanon', countryCode: 'LB'},
  {countryName: 'Lesotho', countryCode: 'LS'},
  {countryName: 'Liberia', countryCode: 'LR'},
  {countryName: 'Libya', countryCode: 'LY'},
  {countryName: 'Liechtenstein', countryCode: 'LI'},
  {countryName: 'Lithuania', countryCode: 'LT'},
  {countryName: 'Luxembourg', countryCode: 'LU'},
  {countryName: 'Macau', countryCode: 'MO'},
  {countryName: 'Macedonia', countryCode: 'MK'},
  {countryName: 'Madagascar', countryCode: 'MG'},
  {countryName: 'Malawi', countryCode: 'MW'},
  {countryName: 'Malaysia', countryCode: 'MY'},
  {countryName: 'Maldives', countryCode: 'MV'},
  {countryName: 'Mali', countryCode: 'ML'},
  {countryName: 'Malta', countryCode: 'MT'},
  {countryName: 'Marshall Islands', countryCode: 'MH'},
  {countryName: 'Mauritania', countryCode: 'MR'},
  {countryName: 'Mauritius', countryCode: 'MU'},
  {countryName: 'Mayotte', countryCode: 'YT'},
  {countryName: 'Mexico', countryCode: 'MX'},
  {countryName: 'Micronesia', countryCode: 'FM'},
  {countryName: 'Moldova', countryCode: 'MD'},
  {countryName: 'Monaco', countryCode: 'MC'},
  {countryName: 'Mongolia', countryCode: 'MN'},
  {countryName: 'Montenegro', countryCode: 'ME'},
  {countryName: 'Morocco', countryCode: 'MA'},
  {countryName: 'Mozambique', countryCode: 'MZ'},
  {countryName: 'Myanmar', countryCode: 'MM'},
  {countryName: 'Namibia', countryCode: 'NA'},
  {countryName: 'Nauru', countryCode: 'NR'},
  {countryName: 'Nepal', countryCode: 'NP'},
  {countryName: 'Netherlands', countryCode: 'NL'},
  {countryName: 'Netherlands Antilles', countryCode: 'AN'},
  {countryName: 'New Caledonia', countryCode: 'NC'},
  {countryName: 'New Zealand', countryCode: 'NZ'},
  {countryName: 'Nicaragua', countryCode: 'NI'},
  {countryName: 'Niger', countryCode: 'NE'},
  {countryName: 'Nigeria', countryCode: 'NG'},
  {countryName: 'Niue', countryCode: 'NU'},
  {countryName: 'North Korea', countryCode: 'KP'},
  {countryName: 'Norway', countryCode: 'NO'},
  {countryName: 'Oman', countryCode: 'OM'},
  {countryName: 'Pakistan', countryCode: 'PK'},
  {countryName: 'Palau', countryCode: 'PW'},
  {countryName: 'Palestine', countryCode: 'PS'},
  {countryName: 'Panama', countryCode: 'PA'},
  {countryName: 'Papua New Guinea', countryCode: 'PG'},
  {countryName: 'Paraguay', countryCode: 'PY'},
  {countryName: 'Peru', countryCode: 'PE'},
  {countryName: 'Philippines', countryCode: 'PH'},
  {countryName: 'Pitcairn', countryCode: 'PN'},
  {countryName: 'Poland', countryCode: 'PL'},
  {countryName: 'Portugal', countryCode: 'PT'},
  {countryName: 'Qatar', countryCode: 'QA'},
  {countryName: 'Republic of the Congo', countryCode: 'CG'},
  {countryName: 'Reunion', countryCode: 'RE'},
  {countryName: 'Romania', countryCode: 'RO'},
  {countryName: 'Russia', countryCode: 'RU'},
  {countryName: 'Rwanda', countryCode: 'RW'},
  {countryName: 'Saint Barthelemy', countryCode: 'BL'},
  {countryName: 'Saint Helena', countryCode: 'SH'},
  {countryName: 'Saint Martin', countryCode: 'MF'},
  {countryName: 'Saint Pierre and Miquelon', countryCode: 'PM'},
  {countryName: 'Samoa', countryCode: 'WS'},
  {countryName: 'San Marino', countryCode: 'SM'},
  {countryName: 'Sao Tome and Principe', countryCode: 'ST'},
  {countryName: 'Saudi Arabia', countryCode: 'SA'},
  {countryName: 'Senegal', countryCode: 'SN'},
  {countryName: 'Serbia', countryCode: 'RS'},
  {countryName: 'Seychelles', countryCode: 'SC'},
  {countryName: 'Sierra Leone', countryCode: 'SL'},
  {countryName: 'Singapore', countryCode: 'SG'},
  {countryName: 'Slovakia', countryCode: 'SK'},
  {countryName: 'Slovenia', countryCode: 'SI'},
  {countryName: 'Solomon Islands', countryCode: 'SB'},
  {countryName: 'Somalia', countryCode: 'SO'},
  {countryName: 'South Africa', countryCode: 'ZA'},
  {countryName: 'South Korea', countryCode: 'KR'},
  {countryName: 'South Sudan', countryCode: 'SS'},
  {countryName: 'Spain', countryCode: 'ES'},
  {countryName: 'Sri Lanka', countryCode: 'LK'},
  {countryName: 'Sudan', countryCode: 'SD'},
  {countryName: 'Suriname', countryCode: 'SR'},
  {countryName: 'Svalbard and Jan Mayen', countryCode: 'SJ'},
  {countryName: 'Swaziland', countryCode: 'SZ'},
  {countryName: 'Sweden', countryCode: 'SE'},
  {countryName: 'Switzerland', countryCode: 'CH'},
  {countryName: 'Syria', countryCode: 'SY'},
  {countryName: 'Taiwan', countryCode: 'TW'},
  {countryName: 'Tajikistan', countryCode: 'TJ'},
  {countryName: 'Tanzania', countryCode: 'TZ'},
  {countryName: 'Thailand', countryCode: 'TH'},
  {countryName: 'Togo', countryCode: 'TG'},
  {countryName: 'Tokelau', countryCode: 'TK'},
  {countryName: 'Tonga', countryCode: 'TO'},
  {countryName: 'Tunisia', countryCode: 'TN'},
  {countryName: 'Turkey', countryCode: 'TR'},
  {countryName: 'Turkmenistan', countryCode: 'TM'},
  {countryName: 'Tuvalu', countryCode: 'TV'},
  {countryName: 'Uganda', countryCode: 'UG'},
  {countryName: 'Ukraine', countryCode: 'UA'},
  {countryName: 'United Arab Emirates', countryCode: 'AE'},
  {countryName: 'United Kingdom', countryCode: 'GB'},
  {countryName: 'United States', countryCode: 'US'},
  {countryName: 'Uruguay', countryCode: 'UY'},
  {countryName: 'Uzbekistan', countryCode: 'UZ'},
  {countryName: 'Vanuatu', countryCode: 'VU'},
  {countryName: 'Vatican', countryCode: 'VA'},
  {countryName: 'Venezuela', countryCode: 'VE'},
  {countryName: 'Vietnam', countryCode: 'VN'},
  {countryName: 'Wallis and Futuna', countryCode: 'WF'},
  {countryName: 'Western Sahara', countryCode: 'EH'},
  {countryName: 'Yemen', countryCode: 'YE'},
  {countryName: 'Zambia', countryCode: 'ZM'},
  {countryName: 'Zimbabwe', countryCode: 'ZW'}
];

import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PhotobookInterceptorService} from './services/photobook-interceptor.service';
import {LoginComponent} from './components/login/login.component';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {ProfileComponent} from './components/profile/profile.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {FacebookLoginProvider, SocialAuthServiceConfig, SocialLoginModule} from 'angularx-social-login';
import {CreateBookComponent} from './components/create-book/create-book.component';
import {BookEditorComponent} from './components/book-editor/book-editor.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ReorderableListComponent} from './components/reorderable-list/reorderable-list.component';
import {ImageEditorComponent} from './components/image-editor/image-editor.component';
import {LayoutChangeComponent} from './components/layout-change/layout-change.component';
import {TimeAgoPipe} from './pipes/time-ago.pipe';
import {InputDialogComponent} from './components/input-dialog/input-dialog.component';
import {ConfigureBookDialogComponent} from './components/configure-book-dialog/configure-book-dialog.component';
import {CheckoutComponent} from './components/checkout/checkout.component';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {OkDialogComponent} from './components/ok-dialog/ok-dialog.component';
import {BasketComponent} from './components/basket/basket.component';
import {DatePipe} from '@angular/common';
import {OrderComponent} from './components/order/order.component';
import {environment} from '../environments/environment';
import { ChangePhotoDialogComponent } from './components/change-photo-dialog/change-photo-dialog.component';
import { FullScreenProgressComponent } from './components/full-screen-progress/full-screen-progress.component';

@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent,
    NotFoundComponent,
    LoginComponent,
    CreateBookComponent,
    BookEditorComponent,
    ReorderableListComponent,
    ImageEditorComponent,
    LayoutChangeComponent,
    ConfigureBookDialogComponent,
    TimeAgoPipe,
    InputDialogComponent,
    OkDialogComponent,
    CheckoutComponent,
    BasketComponent,
    OrderComponent,
    ChangePhotoDialogComponent,
    FullScreenProgressComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ProgressbarModule.forRoot(),
    SocialLoginModule,
    DragDropModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: PhotobookInterceptorService, multi: true},
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookAppId),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    TimeAgoPipe,
    DatePipe,
    {provide: 'googleTagManagerId', useValue: environment.gtmKey}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

<div class="modal-backdrop fade show"></div>
<div class="modal fade bd-example-modal-xl show" tabindex="-1" role="dialog" data-backdrop="static"
     aria-modal="true" style="display: block;" (click)="closeDialog.emit(false)" [style.cursor]="'pointer'">
  <div class="modal-dialog modal-dialog-centered" role="document" (click)="$event.stopPropagation();">
    <div class="modal-content">
      <div class="modal-header" *ngIf="title">
        <h5 class="modal-title" [innerHTML]="title"></h5>
      </div>
      <div class="modal-body" [innerHTML]="body" [style.textAlign]="title ? 'left' : 'center'">
      </div>
      <div class="modal-footer" [style.justifyContent]="title ? 'flex-end' : 'center'">
        <button type="button" class="btn btn--outline" (click)="closeDialog.emit(false)"
                *ngIf="negativeButtonText != undefined && negativeButtonText != null">
          {{negativeButtonText}}
        </button>
        <button type="button" class="btn btn--gradient" (click)="closeDialog.emit(true)">
          {{positiveButtonText}}
        </button>
      </div>
    </div>
  </div>
</div>

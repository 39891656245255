import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss']
})
export class InputDialogComponent implements OnInit {
  newDialogTitle: string;
  @Input() set initialText(value: string) {
    if (value) {
      this.newDialogTitle = value;
    }
  }
  @Input() title = '';
  @Input() multilineText = false;
  @Input() maxLength = 50;
  @Input() placeholder = '';
  @Input() negativeButtonText: string;
  @Input() positiveButtonText = 'OK';
  @Output() closeDialog = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

}

<main>
  <div class="modal-backdrop fade show"></div>
  <div class="modal fade bd-example-modal-xl show" tabindex="-1" role="dialog" data-backdrop="static"
       aria-modal="true" style="display: block;">
    <div class="modal-dialog modal-l" role="document" [formGroup]="formGroup">
      <div class="modal-content">
        <div class="modal-header my-4">
          <span class="title">Customize your order</span>
        </div>
        <div class="modal-separator">
          <h6 class="modal-title">Select size</h6>
        </div>
        <div class="modal-body">
          <img class="img-fluid mb-2" [style.width]="'100%'" [src]="book?.orientation === 'landscape' ?
          './assets/img/landscape_book.png' : (book?.orientation === 'square' ? './assets/img/square_book.png' : './assets/img/portrait_book.png')"
               alt="Responsive image">
          <div class="rounded-border rounded-border--blue mb-3 d-flex product-count-setter-container">
            <div class="d-flex py-3 product-count-setter" *ngIf="priceOfLargeBook > 0">
              <div class="text-center" [style.width]="'120px'">
                {{book.orientation == 'square' ? 'Extra Large' : 'Large'}}
                <br>
                <ng-container *ngIf="book.orientation == 'square'">
                  (29.4 x 29.4 cm)
                </ng-container>
                <ng-container *ngIf="book.orientation == 'portrait'">
                  (21.0 x 29.7 cm)
                </ng-container>
                <ng-container *ngIf="book.orientation == 'landscape'">
                  (29.7 x 21.0 cm)
                </ng-container>
                <br>
                Base price: <strong>${{ priceOfLargeBook }}</strong>
              </div>
              <div [style.width]="'170px'">
                <div class="numberic-updown">
                  <button (click)="decreaseNumberOfLargeOrder()" class="fas fa-plus"
                          [disabled]="isNaN(Number(formGroup.controls.numberOfLargeOrder.value)) || formGroup.controls.numberOfLargeOrder.value == 0">
                    <fa-icon [icon]="faMinus"></fa-icon>
                  </button>
                  <input formControlName="numberOfLargeOrder" type="number" class="form-control text-center" value="1"
                         placeholder="0"
                         [style.borderColor]="formGroup.controls.numberOfLargeOrder.invalid ? 'red' : '#ced4da'">
                  <button (click)="increaseNumberOfLargeOrder()" class="fas fa-minus">
                    <fa-icon [icon]="faPlus"></fa-icon>
                  </button>
                </div>
              </div>
            </div>
            <div class="d-flex py-3 product-count-setter" *ngIf="priceOfSmallBook > 0">
              <div class="text-center" [style.width]="'120px'">
                {{book.orientation == 'square' ? 'Large' : 'Small'}}
                <br>
                <ng-container *ngIf="book.orientation == 'square'">
                  (21.0 x 21.0 cm)
                </ng-container>
                <ng-container *ngIf="book.orientation == 'portrait'">
                  (14.8 x 21.0 cm)
                </ng-container>
                <ng-container *ngIf="book.orientation == 'landscape'">
                  (21.0 x 14.8 cm)
                </ng-container>
                <br>
                Base price: <strong>${{ priceOfSmallBook }}</strong>
              </div>
              <div [style.width]="'170px'">
                <div class="numberic-updown">
                  <button (click)="decreaseNumberOfSmallOrder()" class="fas fa-plus"
                          [disabled]="isNaN(Number(formGroup.controls.numberOfSmallOrder.value)) || formGroup.controls.numberOfSmallOrder.value == 0">
                    <fa-icon [icon]="faMinus"></fa-icon>
                  </button>
                  <input formControlName="numberOfSmallOrder" type="number" class="form-control text-center" value="1"
                         placeholder="0"
                         [style.borderColor]="formGroup.controls.numberOfSmallOrder.invalid ? 'red' : '#ced4da'">
                  <button href="#" (click)="increaseNumberOfSmallOrder()" class="fas fa-minus">
                    <fa-icon [icon]="faPlus"></fa-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-separator">
          <h6 class="modal-title">Select upgrades</h6>
        </div>
        <div formArrayName="features" *ngFor="let orderFeature of orderFeatures; let i = index"
             class="modal-body feature-container">
          <div class="mb-2">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="customCheck{{i + 1}}" name="example2"
                     formControlName="{{i}}">
              <label class="custom-control-label" for="customCheck{{i + 1}}"> {{ orderFeature.name }}
                <strong>${{ orderFeature.price }}</strong></label>
            </div>
          </div>
          <div class="position-relative mb-2">
            <img *ngIf="orderFeature.imageSource" class="img-fluid mb-2" src="{{ orderFeature.imageSource }}"
                 alt="Responsive image">
            <div *ngIf="orderFeature.recommended" class="label label--bottom">Recommended</div>
          </div>
          <p class="mb-0">{{ orderFeature.description }}</p>
          <hr *ngIf="i !== orderFeatures.length - 1">
        </div>
      </div>
    </div>
  </div>
</main>
<footer class="footer footer--modal-footer footer--auto-height bg-white">
  <div class="configure-footer-row">
    <div class="d-flex flex-column align-items-center justify-content-center">
      <div class="rounded-border book-cover" [style.backgroundImage]="'url(\'' + book.cover.photoList[0].url + '\')'"></div>
      <a href="#" (click)="$event.preventDefault(); closeDialog.emit()">Edit book</a>
    </div>
    <div class="footer-center-column">
      <p class="mb-0">
        <strong>Order Summary:</strong>
      </p>
      <p class="mb-3 mobile">
        Pages: {{ numberOfPages }} <br>
        Books: {{ selectedBooks }} <br>
        Extras: {{ selectedExtras }}
      </p>
      <p class="mb-3 desktop">
        Pages: <strong>{{ numberOfPages }}</strong> | Books: <strong>{{ selectedBooks }}</strong> | Extras:
        <strong>{{ selectedExtras }}</strong>
      </p>
      <p class="mb-0">
        <strong [style.color]="formGroup.invalid ? 'red' : 'inherit'">
          Subtotal: {{ (subtotal != undefined || subtotal != null) ? ('$' + (subtotal | number:'1.0-2')) : 'There is an error in amount' }}
        </strong>
      </p>
    </div>
    <button type="button" class="btn btn--gradient btn--full-height px-5"
            (click)="configureBook()" [disabled]="formGroup.invalid ||
                (formGroup.controls.numberOfLargeOrder.value == 0 && formGroup.controls.numberOfSmallOrder.value == 0)">
      Next
    </button>
  </div>
</footer>
